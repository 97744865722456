<div class = "content">
    <div class = "title">
        <img class = "app-logo" src = "assets/images/app-logo.png" />
        <div class = "text">
            Belépési kód az aktuális órára
        </div>
    </div>
    <div class = "access-code">
        <div>
            {{ accessCode.charAt(0) }}
        </div>
        <div>
            {{ accessCode.charAt(1) }}
        </div>
        <div>
            {{ accessCode.charAt(2) }}
        </div>
        <div>
            {{ accessCode.charAt(3) }}
        </div>
    </div>
    <div class = "button-container">
        <button class = "action-button" (click) = "handleBackButtonClick()" mat-ripple>
            <span class = "material-icons">
                arrow_back
            </span>
            <span>
                Vissza
            </span>
        </button>
    </div>
</div>

