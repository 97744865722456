<!-- Question text container -->
<div class = "question-text-container" >
    <div
        class = "question-text"
        fit-text
        [minFontSize] = "minQuestionTextFontSize"
        [maxFontSize] = "maxQuestionTextFontSize"
    >
        {{ quizSessionQuestion.question.text }}
    </div>
</div>

<!-- Question data container (media and options) -->
<div class = "question-data" [class.has-media] = "quizSessionQuestion.question.mediaUrl">
    <!-- Media container -->
    <div class = "question-media-container">
        <img
            *ngIf = "quizSessionQuestion.question.mediaUrl"
            [src] = "quizSessionQuestion.question.mediaUrl"
            class = "question-media"
        />
    </div>
    <!-- Options container -->
    <div class = "options-container">
        <div
            class = "options"
            fit-text
            [minFontSize] = "minOptionTextFontSize"
            [maxFontSize] = "maxOptionTextFontSize"
        >
            <app-quiz-session-question-option
                *ngFor = "let option of quizSessionQuestion.question.options; let i = index"
                [option] = "option"
                [userAnswers] = "quizSessionQuestion.answers"
                [index] = "i"
                [quizSessionRef] = "quizSessionRef"
            ></app-quiz-session-question-option>
        </div>
    </div>
</div>
<!-- Question explanation container -->
<div class = "question-explanation-container">
    <div
        class = "question-explanation"
        *ngIf = "quizSessionRef.isActualQuestionEvaulated"
    >
        <div class = "question-explanation-title">
            Magyarázat:
        </div>
        <div
            class = "question-explanation-text"
            fit-text
            [minFontSize] = "minOptionTextFontSize"
            [maxFontSize] = "maxOptionTextFontSize"
        >
            {{ quizSessionQuestion.question.explanation }}
        </div>
    </div>
</div>
