import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { copyArrayToEnd, getFirstElementFromArrayByPredicate, reduceToSum, removeFirstElementFromArrayByPredicate } from 'src/app/functions/array-functions';
import { CustomValidators } from 'src/app/models/common/form';
import { QuestionGroup, questionGroupSorter } from 'src/app/models/question-group';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';
import { QuestionGroupService } from 'src/app/services/question-group.service';
import { AlreadyRunningQuizDialogComponent } from './already-running-quiz-dialog/already-running-quiz-dialog.component';

@Component({
  selector: 'app-create-quiz',
  templateUrl: './create-quiz.component.html',
  styleUrls: ['./create-quiz.component.scss']
})
export class CreateQuizComponent implements OnInit, OnDestroy {

  @ViewChild("questionCountInput") questionCountInput:ElementRef<HTMLInputElement>;

  questionGroupSelectFC:FormControl;
  questionCountFC:FormControl;
  formGroup:FormGroup;

  selectedQuestionGroups:Array<{ questionGroup:QuestionGroup, count: number }>;
  unassignedQuestionGroups:Array<QuestionGroup>;
  numberOfQuestionsInQuiz:number = 0;

  isQuestionGroupsLoading:boolean;
  questionGroupValueChangesSubscription:Subscription;
  baseQuestionCountValidators:Array<ValidatorFn> = [ Validators.required, Validators.min(1), CustomValidators.wholeNumber ];

  isActionButtonLoading:boolean = false;

  constructor(
    private questionGroupService:QuestionGroupService,
    private ongoingClassroomService:OngoingClassroomService,
    private routerService:Router,
    private activatedRoute:ActivatedRoute,
    private snackBarService:MatSnackBar,
    private dialogService:MatDialog
  ) {
    this.unassignedQuestionGroups = new Array<QuestionGroup>();
    this.selectedQuestionGroups = [];

    this.questionGroupSelectFC = new FormControl(null);
    this.questionCountFC = new FormControl(1, [ ...this.baseQuestionCountValidators ]);
    this.formGroup = new FormGroup({
      questionGroupSelectFC: this.questionGroupSelectFC,
      questionCountFC: this.questionCountFC
    });
  }

  public async ngOnInit():Promise<void> {
    this.isQuestionGroupsLoading = true;
    this.questionGroupSelectFC.disable();
    this.questionCountFC.disable();

    try {
      const questionGroups:ReadonlyArray<QuestionGroup> = await this.questionGroupService.getQuestionGroups();
      copyArrayToEnd(this.unassignedQuestionGroups, questionGroups);
      
      this.isQuestionGroupsLoading = false;
      
      this.questionGroupSelectFC.enable();
      this.questionGroupValueChangesSubscription = this.questionGroupSelectFC.valueChanges.subscribe(
        (newValue:QuestionGroup|null) => {
          if(newValue === null) {
            this.questionCountFC.disable();
          } else {
            const selectedQuestionGroup:QuestionGroup|null = this.unassignedQuestionGroups.find(questionGroup => questionGroup.uuid === newValue.uuid) ?? null;
            if(selectedQuestionGroup) {
              const maxValue:number = selectedQuestionGroup.questionCount;
              this.questionCountFC.setValidators([ ...this.baseQuestionCountValidators, Validators.max(maxValue) ]);
              this.questionCountFC.updateValueAndValidity();
              this.questionCountInput.nativeElement.max = maxValue.toString();
              this.questionCountFC.enable();
            }
          }
        }
      );
      
    } catch(error:any) {
      // TODO: hande
    }
  }

  public ngOnDestroy():void {
    this.questionGroupValueChangesSubscription?.unsubscribe();
  }

  public addToSelectedQuestionGroups():void {
    this.selectedQuestionGroups.push({
      questionGroup: this.questionGroupSelectFC.value,
      count: this.questionCountFC.value
    });

    removeFirstElementFromArrayByPredicate(this.unassignedQuestionGroups, questingGroup => questingGroup.uuid === this.questionGroupSelectFC.value.uuid);
    this.questionGroupSelectFC.setValue(null);
    this.questionCountFC.setValue(1);

    this.updateNumberOfQuestionsInQuiz();
  }

  public deleteFromSelectedQuestionGroups(questingGroupUuid:string):void {
    this.unassignedQuestionGroups.push(
      getFirstElementFromArrayByPredicate(this.selectedQuestionGroups, selectedQuestionGroup => selectedQuestionGroup.questionGroup.uuid === questingGroupUuid)?.questionGroup!
    );
    this.unassignedQuestionGroups.sort(questionGroupSorter);
    
    removeFirstElementFromArrayByPredicate(this.selectedQuestionGroups, selectedQuestionGroup => selectedQuestionGroup.questionGroup.uuid === questingGroupUuid);

    this.updateNumberOfQuestionsInQuiz();
  }

  private updateNumberOfQuestionsInQuiz():void {
    this.numberOfQuestionsInQuiz = this.selectedQuestionGroups.map(
      selectedQuestionGroup => selectedQuestionGroup.count
    ).reduce(reduceToSum, 0);
  }

  public async createQuiz():Promise<void> {
    if(this.selectedQuestionGroups.length === 0) {
      return;
    }

    this.isActionButtonLoading = true;

    const generationRule = this.selectedQuestionGroups.map(
      selectedQuestionGroup => {
        return {
          questionGroupUuid: selectedQuestionGroup.questionGroup.uuid,
          count: selectedQuestionGroup.count
        };
      }
    );

    try {
      await this.ongoingClassroomService.startQuizSession({ generationRule: generationRule, isContest: false, timeLimitInSec: null });
      this.routerService.navigate(["../quiz"], { relativeTo: this.activatedRoute });
    } catch(error:any) {
      let openSnackBarMessage:boolean = false;
      let snackBarMessage:string = "Hiba történt a kvíz indítása közben.";
      switch(error.error) {
        case "ALREADY_RUNNING_QUIZ_SESSION":
          this.openQuizAlreadyRunningDialog();
          break;
        case "CLASS_ROOM_NOT_RUNNING":
          openSnackBarMessage = true;
          snackBarMessage = "A kvíz indításához hozz létre egy órát először!";
          break;
        case "MISSING_PARAMETER":
            openSnackBarMessage = true;
            snackBarMessage = "Hibás kvíz létrehozása paraméter.";
            break;
        case "INVALID_QUESTION_COUNT":
            openSnackBarMessage = true;
            snackBarMessage = "Hibás kvíz létrehozása paraméter.";
            break;
        default:
          openSnackBarMessage = true;
        }

        if(openSnackBarMessage) {
          this.snackBarService.open(snackBarMessage , "Bezár", { duration: 3000 });
        }      
      }

    this.isActionButtonLoading = false;
  }

  private openQuizAlreadyRunningDialog():void {
    const dialogRef:MatDialogRef<AlreadyRunningQuizDialogComponent, boolean> = this.dialogService.open(AlreadyRunningQuizDialogComponent);

    const dialogClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
      (userAnswer:boolean|undefined) => {
        dialogClosedSubscription.unsubscribe();
        if(userAnswer) {
          this.routerService.navigate(["../quiz"], { relativeTo: this.activatedRoute });
        }
      }
    );
  }

}
