<div *ngIf = "username" class = "profile">
    <img [src] = "userProfileImageUrl ? userProfileImageUrl : 'assets/defaultprofile.png'" class = "profile-image">
    <span class = "profile-username">
        {{ username }}
    </span>
</div>
<div *ngIf = "isThereCurrentQuiz" class = "menu-item" (click) = "abortQuiz()">
    <span class = "material-icons">
        cancel
    </span>
    <span class = "menu-item-name">
        Kvíz lezárása
    </span>
</div>
<a [routerLink] = "'settings'">
    <div class = "menu-item">
        <span class = "material-icons">
            settings
        </span>
        <span class = "menu-item-name">
            Beállítások
        </span>
    </div>
</a>
<div class = "menu-item" (click) = "logout()">
    <span class = "material-icons">
        lock
    </span>
    <span class = "menu-item-name">
        Kijelentkezés
    </span>
</div>