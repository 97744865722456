<div>
    <button class = "action-button back-button" (click) = "navigateBack()" matRipple>
        <span class = "material-icons">
            arrow_back
        </span>
        <span>
            Vissza
        </span>
    </button>
</div>

<div class = "page-title">
    Beállítások
</div>

<!-- <div class = "page-subtitle">
    Rendszerbeállítások
</div>
<div class = "settings-elements">
    <div class = "settings-element">
        <mat-checkbox color = "primary" [disabled] = "true" > Távirányított mód</mat-checkbox>
    </div>
</div> -->

<div class = "page-subtitle">
    Tanuló lista beállítások
</div>
<div class = "settings-elements">
    <div class = "settings-element">
        <mat-checkbox color = "primary" [formControl] = "showStudentAnswersFC"> Tanulói válaszok mutatása </mat-checkbox>
    </div>
</div>