import { Injectable } from '@angular/core';
import { Classroom, classroomSorter, quizSessionSorter } from '../models/classroom';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PreviousClassroomService {
  private static readonly apiUrlFragment:string = "/api/instructor/classroom/classroom_histories"

  private previousClassrooms:Array<Classroom>;

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.previousClassrooms = new Array<Classroom>();
  }

  public getPreviousClassroomsArrayRef():ReadonlyArray<Classroom> {
    return this.previousClassrooms;
  }

  public async getPreviousClassrooms():Promise<ReadonlyArray<Classroom>> {
    try {
      const response:{ histories:Array<Classroom> } = await this.backendService.callApi(PreviousClassroomService.apiUrlFragment, "GET");
      this.previousClassrooms.length = 0;
      response.histories.forEach(
        classroom => {
          classroom.quizSessionHistory.sort(quizSessionSorter);
          this.previousClassrooms.push(
            new Classroom(classroom.uuid, classroom.accessId, classroom.created, classroom.visitors, classroom.quizSessionHistory)
          );
        }
      );
      this.previousClassrooms.sort(classroomSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a órák lekrédezése közben.");
    }

    return this.previousClassrooms;
  }

  public addPreviousClassroom(previousClassroom:Classroom):void {
    this.previousClassrooms.push(previousClassroom);
    this.previousClassrooms.sort(classroomSorter);
  }

  public clearCahcedData():void {
    this.previousClassrooms.length = 0;
  }

}
