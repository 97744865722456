import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  @Input() icon:string;
  @Input() backgroundColor:string = "white";

  constructor(
    private elementRef:ElementRef<HTMLElement>
  ) { }

  public ngOnInit():void {
    this.elementRef.nativeElement.style.backgroundColor = this.backgroundColor;
  }
}
