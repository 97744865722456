import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FitTextDirective } from 'src/app/directives/fit-text.directive';
import { QuizSession, QuizSessionQuestion } from 'src/app/models/classroom';

@Component({
  selector: 'app-quiz-session-question',
  templateUrl: './quiz-session-question.component.html',
  styleUrls: ['./quiz-session-question.component.scss']
})
export class QuizSessionQuestionComponent implements OnInit, OnDestroy {
  @Input() quizSessionQuestion:QuizSessionQuestion;
  @Input() quizSessionRef:QuizSession;

  @ViewChild("div", { static: true }) testDiv:ElementRef<HTMLElement>;

  readonly fontSizeOnMobile:number = 20;
  readonly fontSizeOnTablet:number = 30;

  minQuestionTextFontSize:number = 1;
  maxQuestionTextFontSize:number = 200;

  minOptionTextFontSize:number = 1;
  maxOptionTextFontSize:number = 200;

  resizeObserver:ResizeObserver;

  constructor() {
    this.resizeObserver = new ResizeObserver(this.onResize)
  }

  ngOnInit():void {
    this.resizeObserver.observe(document.body);
    this.setMinMaxFontSizes(document.body.clientWidth);
  }

  ngOnDestroy():void {
    this.resizeObserver.disconnect();
  }

  private onResize = () => {
    this.setMinMaxFontSizes(document.body.clientWidth);
  }

  private setMinMaxFontSizes(width:number):void {
    if(width < 500) {
      this.minQuestionTextFontSize = this.fontSizeOnMobile;
      this.maxQuestionTextFontSize = this.fontSizeOnMobile;

      this.minOptionTextFontSize = this.fontSizeOnMobile - 5;
      this.maxOptionTextFontSize = this.fontSizeOnMobile - 5;
    } else if(width < 1000) {
      this.minQuestionTextFontSize = this.fontSizeOnTablet;
      this.maxQuestionTextFontSize = this.fontSizeOnTablet;

      this.minOptionTextFontSize = this.fontSizeOnTablet - 10;
      this.maxOptionTextFontSize = this.fontSizeOnTablet - 10;
    } else {
      this.minQuestionTextFontSize = FitTextDirective.defaultMinFontSizeInPixels;
      this.maxQuestionTextFontSize = FitTextDirective.defaultMaxFontSizeInPixels;

      this.minOptionTextFontSize = FitTextDirective.defaultMinFontSizeInPixels;
      this.maxOptionTextFontSize = Math.min(FitTextDirective.defaultMaxFontSizeInPixels, 72);
    }
  }
}
