import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { QuizSession } from 'src/app/models/classroom';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { OngoingClassroomService, QuizStateChangeEvent } from 'src/app/services/ongoing-classroom.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  @Input() mobileMode:boolean;
  @Input() username?:string;
  @Input() userProfileImageUrl?:string;

  @Output() menuItemClicked:EventEmitter<void> = new EventEmitter<void>();

  isThereCurrentQuiz:boolean = false;

  quizStateChangeSubscription:Subscription;

  constructor(
    private authenticationService:AuthenticationService,
    private ongoingClassroomService:OngoingClassroomService,
    private snackBarService:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.isThereCurrentQuiz = (this.ongoingClassroomService.getOngoingClassroomRef()?.currentQuizSession ?? null) !== null;

    this.quizStateChangeSubscription = this.ongoingClassroomService.getCurrentQuizSessionChangeSubscription(
      (newState:{ quizSession:QuizSession|null, event: QuizStateChangeEvent }) => {
        this.isThereCurrentQuiz = newState.quizSession !== null;
      }
    );
  }

  ngOnDestroy():void {
    this.quizStateChangeSubscription?.unsubscribe();
  }

  public async abortQuiz():Promise<void> {
    this.menuItemClicked.emit();

    try {
      await this.ongoingClassroomService.abortQuizSession();
    } catch(error:any) {
      this.snackBarService.open("Hiba a kvíz lezárása közben.", "Bezár", { duration: 3000 });
    }
  }

  public async logout():Promise<void> {
    try {
      await this.authenticationService.logout();
    } catch(error:any) {
      // TODO:
      console.error(error);
    }
  }

}
