import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, Event, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { SocketService } from 'src/app/services/common/socket.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  isConnectionLoading:boolean;
  infoText:string;
  isHeaderShown:boolean = false;

  routeChangeSubscription:Subscription;
  resizeObserver:ResizeObserver;

  socketAuthenticatedSubscription:Subscription;

  constructor(
    public elementRef:ElementRef<HTMLElement>,
    private changeDetectorRef:ChangeDetectorRef,
    private socketService:SocketService,
    private routerService:Router
  ) {
    this.resizeObserver = new ResizeObserver(() => { this.setHeaderAndPadding(); });
  }

  public async ngOnInit():Promise<void> {
    this.routeChangeSubscription = this.routerService.events.subscribe(
      (routerEvent:Event) => {
       this.setHeaderAndPadding(routerEvent);
      }
    );

    this.socketAuthenticatedSubscription = this.socketService.getSocketAuthenticatedSubscription(
      () => {
        if(this.isConnectionLoading) {
          this.isConnectionLoading = false;
          this.setHeaderAndPadding();
        }
      }
    )

    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.isConnectionLoading = true;

    this.infoText = "Csatlakozás a szerverhez...";
    this.socketService.connect();
  }

  public ngOnDestroy():void {
    this.routeChangeSubscription?.unsubscribe();
    this.socketAuthenticatedSubscription?.unsubscribe();
  }

  private setHeaderAndPadding = (routerEvent?:Event) => {
    if(routerEvent) {
      if(routerEvent instanceof NavigationStart) {
        this.isHeaderShown = this.shouldShowHeader(routerEvent.url);
      } else {
        return;
      }
    } else {
      this.isHeaderShown = this.shouldShowHeader(this.routerService.url)
    }

    if(this.isHeaderShown) {
      if(this.elementRef.nativeElement.clientWidth < 1500) {
        this.elementRef.nativeElement.style.paddingTop = "50px";
      } else {
        this.elementRef.nativeElement.style.paddingTop = "max(50px, 5vmin)";
      }
    } else {
      this.elementRef.nativeElement.style.removeProperty("padding-top");
    }

    this.changeDetectorRef.detectChanges();
  }

  private shouldShowHeader(url:string):boolean {
    // Below 1000px we always show the header
    if(this.elementRef.nativeElement.clientWidth >= 1000) {
      // We not show the header if we displaying the access code
      if(url.includes("access-code")) {
        return false;
      }

      // Or if we displaying a (full screen) quiz question
      if(url.includes("quiz") && !url.includes("summary") && !url.includes("create-quiz")) {
        return false;
      }
    }

    return true;
  }

}
