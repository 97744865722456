<ng-template [ngIf] = "currentQuizSession">
    <!-- Header -->
    <div class = "quiz-header">
        <div>
            <button
                class = "quiz-action-button header-button back-button"
                (click) = "navigateBack()"
                mat-ripple
            >
                <span class = "material-icons">
                    arrow_back
                </span>
                <span>
                    Vissza
                </span>
            </button>
        </div>


        <div>
          <button
              class = "quiz-action-button header-button"
              (click) = "onTapTopButton()"
              mat-ripple
          >
              <span class = "material-icons">
                  star
              </span>
              <span>
                  TOP 20
              </span>
          </button>
      </div>

        <div class = "question-counter">
            {{ currentQuizSession.actualQuestionIndex + 1 }} / {{ currentQuizSession.questions.length }} kérdés
        </div>
            
        <div>
            <button
                class = "quiz-action-button header-button abort-quiz-button"
                [disabled] = "isAbortQuizButtonLoads"
                [class.button-loading] = "isAbortQuizButtonLoads"
                (click) = "abortQuizSession()"
                mat-ripple
            >
                <span class = "material-icons">
                    clear
                </span>
                <span>
                    Kvíz lezárása
                </span>
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class = "quiz-content">
        <app-quiz-session-question
            [quizSessionQuestion] = "currentQuizSession.questions[currentQuizSession.actualQuestionIndex]"
            [quizSessionRef] = "currentQuizSession"
        ></app-quiz-session-question>
    </div>

    <!-- Footer -->
    <div class = "quiz-footer">
        <div class = "app-icon-container">
            <img src = "assets/images/app-logo.png" />
        </div>
        <button
            *ngIf = "currentQuizSession.isActualQuestionEvaulated"
            class = "gradient-button quiz-action-button footer-button"
            [disabled] = "isActionButtonDisabled || isActionButtonLoads"
            [class.button-loading] = "isActionButtonLoads"
            (click) = "moveToNextQuestion()"
            mat-ripple
        >
            {{ currentQuizSession.actualQuestionIndex < currentQuizSession.questions.length - 1 ? 'Tovább' : 'Kvíz lezárása' }}
        </button>
        <button
            *ngIf = "!currentQuizSession.isActualQuestionEvaulated"
            class = "gradient-button quiz-action-button footer-button"
            [disabled] = "isActionButtonDisabled || isActionButtonLoads"
            [class.button-loading] = "isActionButtonLoads"
            (click) = "evaulateQuestion()"
            mat-ripple
        >
            Kiértékelés
        </button>
        <button
            (click) = "openStudentListDialog()"
            class = "quiz-action-button footer-button student-list-button"
            mat-ripple
        >
            <span class = "students-text">
                Tanulók
            </span>
            <span class = "answers-text">
                Beérkezett válaszok: {{ numberOfAnswers }}/{{ ongoingClassroom?.activeStudentsInQuiz?.length }}
            </span>
        </button>
    </div>
</ng-template>