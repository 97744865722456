<div *ngIf = "isConnectionLoading" class = "generic-loading-container">
    <div>
        <mat-spinner class = "loading-spinner"></mat-spinner>
        <div class = "loading-text">
            {{ infoText }}
        </div>
    </div>
    
</div>
<ng-container *ngIf = "!isConnectionLoading">
    <app-header *ngIf = "isHeaderShown" [parentElementRef] = "elementRef"></app-header>
    <router-outlet></router-outlet>
</ng-container>
