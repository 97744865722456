<div class = "option-wrapper">
    <div
        class = "option-box"
        [style] = "{ 'background-color': quizSessionRef.isActualQuestionEvaulated && option.isSolution ? '#8CD164' : 'white' }"
    >
        <div class = "option-box-letter mrkresz-gradient-background">
            <span>
                {{ letter }}
            </span>
        </div>
        <div class = "option-text">
            {{ option.text }}
        </div>
    </div>
    <div class = "result-percentage-container">
        <div class = "percentage-value" [ngStyle] = "{ 'display': quizSessionRef.isActualQuestionEvaulated ? 'block' : 'none' }">
            {{ percentage }}%
        </div>
    </div>
</div>
