<!-- Header -->
<div class = "quiz-header">
    <button
        class = "quiz-action-button header-button back-button"
        (click) = "navigateBack()"
        mat-ripple
    >
        <span class = "material-icons">
            arrow_back
        </span>
        <span>
            Vissza
        </span>
    </button>
    <div class = "question-counter">
        {{ quizSession.actualQuestionIndex + 1}} / {{ quizSession.questions.length }} kérdés
    </div>
</div>

<!-- Content -->
<div class = "quiz-content">
    <app-quiz-session-question
        [quizSessionQuestion] = "quizSession.questions[quizSession.actualQuestionIndex]"
        [quizSessionRef] = "quizSession"
    ></app-quiz-session-question>
</div>

<!-- Footer -->
<div class = "quiz-footer">
    <div class = "app-icon-container">
        <img src = "assets/images/app-logo.png" />
    </div>
    <button
        class = "gradient-button quiz-action-button footer-button"
        [disabled] = "quizSession.actualQuestionIndex === 0"
        (click) = "moveToPreviousQuestion()"
        mat-ripple
    >
        Előző
    </button>
    <button
        class = "gradient-button quiz-action-button footer-button"
        [disabled] = "quizSession.actualQuestionIndex === quizSession.questions.length - 1"
        (click) = "moveToNextQuestion()"
        mat-ripple
    >
        Következő
    </button>
</div>