import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OngoingClassroom } from 'src/app/models/classroom';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';

@Component({
  selector: 'app-access-code-view',
  templateUrl: './access-code-view.component.html',
  styleUrls: ['./access-code-view.component.scss']
})
export class AccessCodeViewComponent implements OnInit {
  accessCode:string = "0000";

  constructor(
    private ongoingClassroomService:OngoingClassroomService,
    private routerService:Router,
    private activatedRoute:ActivatedRoute
  ) { }

  public async ngOnInit():Promise<void> {    
    const ongoingClassroom:OngoingClassroom|null = this.ongoingClassroomService.getOngoingClassroomRef();
    
    if(ongoingClassroom === null) {
      await this.routerService.navigate(["dashboard/classrooms/list"]);
      return;
    }

    this.accessCode = ongoingClassroom.accessId;
  }

  public async handleBackButtonClick():Promise<void> {
    await this.routerService.navigate([ ".." ], { relativeTo: this.activatedRoute });
  }


}
