import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fancy-card',
  templateUrl: './fancy-card.component.html',
  styleUrls: ['./fancy-card.component.scss']
})
export class FancyCardComponent implements OnInit {
  @Input() headerClass:string = "";
  @Input() contentClass:string = "";

  @ViewChild("header", { static: true, read: ElementRef }) headerElement:ElementRef<HTMLElement>; 
  @ViewChild("content", { static: true, read: ElementRef }) contentElement:ElementRef<HTMLElement>; 

  constructor() { }

  ngOnInit(): void {
    if(this.headerClass) {
      this.headerElement.nativeElement.classList.add(this.headerClass);
    }

    if(this.contentClass) {
      this.contentElement.nativeElement.classList.add(this.contentClass);
    }
  }

}
