<div class = "title">
    <div style = "align-self: center;">
        <ng-template [ngIf] = "!isThereCurrentQuizSession">
            Csatlakozott tanulók ({{ ongoingClassroom.activeStudents.length }})
        </ng-template>
        <ng-template [ngIf] = "isThereCurrentQuizSession">
            Beérkezett válaszok: {{ numberOfAnswers }}/{{ ongoingClassroom.activeStudentsInQuiz.length }}
        </ng-template>
    </div>
    <div class = "expand-icon-wrapper">
        <div class = "expand-icon-container" (click) = "toogleExpansionStatus()">
            <span class = "material-icons" style = "vertical-align: bottom;">
                {{ isExpanded ? 'expand_more' : 'expand_less' }}
            </span>
        </div>
    </div>
</div>
<div class = "active-students-list" [ngClass] = "isExpanded ? 'expanded' : ''">
    <app-student-lists-container
        [isThereCurrentQuizSession] = "isThereCurrentQuizSession"
        [ongoingClassroom] = "ongoingClassroom"
        (numberOfAnswersChangeEvent) = "handleNumberOfAnswersChangeEvent($event)"
    ></app-student-lists-container>
</div>