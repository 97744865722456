import { Injectable, Injector } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { AuthenticationService, AuthenticationResponseString } from './authentication.service';
import { SessionService, UserSession } from './session.service';
import { Platform } from '@angular/cdk/platform';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket:SocketIOClient.Socket;

  private socketAuthenticatedSubject:Subject<void>;

  constructor(
    private sessionService:SessionService,
    private platform:Platform,
    private injector:Injector
  ) {
    this.socket = io(
      environment.classroomAddress,
      {
        transports: [ 'websocket' ],
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionAttempts: Infinity
      }
    );

    this.socketAuthenticatedSubject = new Subject<void>();

    this.addDefaultHandlersToSocket();
  }

  public getSocketAuthenticatedSubscription(next:() => void):Subscription {
    return this.socketAuthenticatedSubject.subscribe(next);
  }

  public connect():void {
    this.socket.connect();
  }

  private async authenticateSocket():Promise<"ACCESS_GRANTED"|"ACCESS_DENIED"> {
    const userSession:Readonly<UserSession|null> = this.sessionService.getUserSession();
    
    const respone:{ result:"ACCESS_GRANTED"|"ACCESS_DENIED" } = await this.emit("auth", { uuid: userSession?.uuid, sessionId: userSession?.sessionId, clientType: "instructor" });
    if(!["ACCESS_GRANTED", "ACCESS_DENIED"].includes(respone.result)) {
      throw { error: "INVALID_RESPONSE" };
    }

    return respone.result;
  }

  public async emit<SentDataType = any, ResponseDataType = any>(eventName:string, data:SentDataType = ({} as any)):Promise<ResponseDataType> {
    return new Promise<ResponseDataType>((resolve, reject) => {
      if(this.socket.disconnected) {
        reject(new Error("The socket is not authenticated or not connected!"));
        return;
      }

      this.socket.emit(eventName, data ?? {}, (response:any) => {
        if(response.error === undefined) {
          resolve(response);
        } else {
          console.error(`An error occured for '${eventName}'`);
          console.error(response);
          reject(response);
        }
      });
    });
  }

  public addOnHandlerToSocket(eventName:string, handler:Function, addOnlyIfNotAdded:boolean = true):void {
    if(addOnlyIfNotAdded && this.socket.hasListeners(eventName)) {
      return;
    }

    this.socket.on(eventName, handler);
  }

  private addDefaultHandlersToSocket():void {
    this.socket.on("connect", this.onConnectHandler);
    this.socket.on("disconnect", this.onDisconnectHandler);
    this.socket.on("connect_error", this.onConnectErrorHandler);
    this.socket.on("reconnect_attempt", this.onReconnectAttemptHandler);
  }

  private onConnectHandler:() => Promise<void> = async () => {
    const authenticationService:AuthenticationService = this.injector.get(AuthenticationService);

    console.info(`Connected to ${environment.classroomAddress}`);

    (this.socket as any).sendBuffer = [];

    try {
      const authenticationResult:AuthenticationResponseString = await this.authenticateSocket();
      if(authenticationResult === "ACCESS_GRANTED") {
        console.info("Socket authenticated.");
        this.socketAuthenticatedSubject.next();
      } else {
        console.error(`Authentication failed. Invalid credentials`);
        authenticationService.logout();
      }
    } catch(error:any) {
      console.error(`Unexpected response value during socket authentication.`);
      authenticationService.logout();
    }
  }

  private onDisconnectHandler:(reason:string) => void = (reason:string) => {
    const authenticationService:AuthenticationService = this.injector.get(AuthenticationService);

    if((this.platform.FIREFOX && reason === "transport error") || (!this.platform.FIREFOX && reason === "transport close")) {
      console.info(`The socket lost the connection with the server. The reason was '${reason}'.`)
      authenticationService.logout();
    }

    if(reason === "ping timeout") {
      console.info("The connection with the server is lost.");
    }
  };

  private onConnectErrorHandler:(e:any) => void = (e:any) => {
    // console.log("connect_error:");
    // console.log(e);
    // console.log("connect_error end");
    // this.snackBarService.open("A kapcsolat a szerverrel megszakadt.", "Bezár", { duration: 3000 });
    // authenticationService.logout();
  };

  private onReconnectAttemptHandler:(reconnectTryCounter:number) => void = (reconnectTryCounter:number) => {
    console.info(`Trying to reconnect... (attempt: ${reconnectTryCounter})`);
  }

  public disconnectSocket():void {
    this.socket.disconnect();
  }
}
