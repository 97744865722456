import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Classroom } from 'src/app/models/classroom';
import { PreviousClassroomService } from 'src/app/services/previous-classroom.service';

@Component({
  selector: 'app-previous-classroom',
  templateUrl: './previous-classroom.component.html',
  styleUrls: ['./previous-classroom.component.scss']
})
export class PreviousClassroomComponent implements OnInit {
  classroom:Classroom|null = null;

  constructor(
    private activatedRoute:ActivatedRoute,
    private previousClassroomService:PreviousClassroomService,
    private routerService:Router,
    private snackbarService:MatSnackBar
  ) {
    const classroomUuid:string|undefined = this.activatedRoute.snapshot.params.classroomUuid;
    if(classroomUuid) {
      this.classroom = this.previousClassroomService.getPreviousClassroomsArrayRef().find(
        classroom => classroom.uuid === classroomUuid
      ) ?? null;
    }
  }

  ngOnInit(): void {
    if(this.classroom === null) {
      this.routerService.navigate([ ".." ], { relativeTo: this.activatedRoute });
      this.snackbarService.open("Hiba az óra lekérdezése közben", "Bezár", { duration: 3000 });
    }
  }

}
