<div class = "header">
    <div class = "title">
        Aktuális óra
    </div>
</div>
<div class = "content">
    <div class = "data-row">
        <div class = "icon-container">
            <span class = "material-icons"> schedule </span>
        </div>
        <div>
            {{ startedAgoString }}
        </div>
    </div>
    <div class = "data-row">
        <div class = "icon-container">
            <span class = "material-icons"> account_circle </span>
        </div>
        <div>
            {{ ongoingClassroom.activeStudents.length }} tanuló vesz részt az órán
        </div>
    </div>
    <div class = "button-container">
        <a [routerLink] = "'../ongoing'">
            <button class = "gradient-button enter-classroom-button" mat-ripple>
                Belépés
            </button>
        </a>
    </div>
</div>