import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClassroomListComponent } from './components/dashboard/classrooms/classroom-list/classroom-list.component';
import { ClassroomsComponent } from './components/dashboard/classrooms/classrooms.component';
import { OngoingQuizSessionComponent } from './components/dashboard/classrooms/classroom-details/ongoing-quiz-session/ongoing-quiz-session.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/misc/page-not-found/page-not-found.component';
import { AuthorizationGuardService } from './services/common/guards/authorization-guard.service';
import { OngoingClassroomComponent } from './components/dashboard/classrooms/ongoing-classroom/ongoing-classroom.component';
import { QuizSessionSummaryComponent } from './components/dashboard/classrooms/classroom-details/quiz-session-summary/quiz-session-summary.component';
import { ReviewQuizSessionComponent } from './components/dashboard/classrooms/classroom-details/review-quiz-session/review-quiz-session.component';
import { PreviousClassroomComponent } from './components/dashboard/classrooms/previous-classroom/previous-classroom.component';
import { SettingsComponent } from './components/dashboard/settings/settings.component';
import { CreateQuizComponent } from './components/dashboard/classrooms/classroom-details/create-quiz/create-quiz.component';
import { AccessCodeViewComponent } from './components/dashboard/classrooms/classroom-details/access-code-view/access-code-view.component';

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [ AuthorizationGuardService ],
    children: [
      {
        path: "classrooms",
        component: ClassroomsComponent,
        children: [
          { path: "", pathMatch: "full", redirectTo: "list" },
          { path: "list", component: ClassroomListComponent },
          { path: "previous/:classroomUuid", component: PreviousClassroomComponent },
          { path: "previous/:classroomUuid/quiz/:quizNumber/summary", component: QuizSessionSummaryComponent },
          { path: "previous/:classroomUuid/quiz/:quizNumber/review", component: ReviewQuizSessionComponent },
          { path: "previous/:classroomUuid/quiz/:quizNumber", pathMatch: "full", redirectTo: "previous/:classroomUuid/quiz/:quizNumber/summary" },
          {
            path: "ongoing",
            children: [
              { path: "", component: OngoingClassroomComponent },
              { path: "access-code", component: AccessCodeViewComponent },
              { path: "create-quiz", component: CreateQuizComponent },
              { path: "quiz", component: OngoingQuizSessionComponent },
              { path: "quiz/:quizNumber/summary", component: QuizSessionSummaryComponent },
              { path: "quiz/:quizNumber/review", component: ReviewQuizSessionComponent },
              { path: "quiz/:quizNumber", pathMatch: "full", redirectTo: "quiz/:quizNumber/summary" }
            ]
          },
        ]
      },
      { path: "settings", component: SettingsComponent },
      { path: "**", redirectTo: "classrooms" }
    ]
  },
  { path: "login", component: LoginComponent, canActivate: [ AuthorizationGuardService ] },
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
