import { Component, Input, OnInit } from '@angular/core';
import { getOptionPercentageFromAnswers, QuizSession, QuizSessionQuestionAnwser } from 'src/app/models/classroom';
import { QuestionOption } from 'src/app/models/question';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';

@Component({
  selector: 'app-quiz-session-question-option',
  templateUrl: './quiz-session-question-option.component.html',
  styleUrls: ['./quiz-session-question-option.component.scss']
})
export class QuizSessionQuestionOptionComponent implements OnInit {
  @Input() option:QuestionOption;
  @Input() userAnswers:Array<QuizSessionQuestionAnwser>;
  @Input() index:number;
  @Input() quizSessionRef:QuizSession;

  percentage:number = 0;

  letter:string;

  constructor(
    private ongoingClassroomService:OngoingClassroomService
  ) { }

  public ngOnInit():void {
    this.letter = String.fromCharCode('A'.charCodeAt(0) + this.index);
    this.percentage = getOptionPercentageFromAnswers(this.userAnswers, this.index);
    
    this.ongoingClassroomService.getOptionAnswerCountChangesSubscription(
      (newPercentage:{optionIndex:number, percentage:number}) => {
        if(newPercentage.optionIndex === this.index) {
          this.percentage = newPercentage.percentage;
        }
      }
    );
  }
}
