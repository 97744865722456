import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClassMember, getNumberOfAnswersInCurrentQuiz, OngoingClassroom, QuizSession } from 'src/app/models/classroom';
import { OngoingClassroomService, QuizStateChangeEvent } from 'src/app/services/ongoing-classroom.service';
import { ActiveStudentsDialogComponent } from '../../active-students-dialog/active-students-dialog.component';
import { TopUsersDialogComponent } from './top-users-dialog/top-users-dialog.component';

@Component({
  selector: 'app-ongoing-quiz-session',
  templateUrl: './ongoing-quiz-session.component.html',
  styleUrls: ['./ongoing-quiz-session.component.scss']
})
export class OngoingQuizSessionComponent implements OnInit, OnDestroy {
  
  currentQuizSession:QuizSession|null;
  currentQuizSessionChangeSubscription:Subscription;

  isActionButtonDisabled:boolean;
  isActionButtonLoads:boolean = false;
  isAbortQuizButtonLoads:boolean = false;

  ongoingClassroom:OngoingClassroom|null;

  studentAnsweredSubscription:Subscription;
  activeStundetsInQuizUpdatedSubscription:Subscription;
  quizStateChangeSubscription:Subscription;

  numberOfAnswers:number = 0;

  activeStudentsDialogRef:MatDialogRef<ActiveStudentsDialogComponent>|null = null;

  constructor(
    private ongoingClassroomService:OngoingClassroomService,
    private routerService:Router,
    private activatedRoute:ActivatedRoute,
    private dialogService:MatDialog,
    private snackBarService:MatSnackBar
  ) {
    this.ongoingClassroom = this.ongoingClassroomService.getOngoingClassroomRef();
    this.currentQuizSession = this.ongoingClassroom?.currentQuizSession ?? null;
  }

  getTopUsers() : {userUuid: string, score: number}[]{
    // userUuid, score
    const userMap: Map<string,number> = new Map<string,number>();

    for(const questions of this.currentQuizSession?.questions ?? []){
      for(const answer of questions.answers){
        if(answer.correct){
          if(userMap.get(answer.userUuid) == null){
            userMap.set(answer.userUuid,1);
          }else{
            userMap.set(answer.userUuid,userMap.get(answer.userUuid)!+1);
          }
        }
      }
    }

    // sort map and get objects: {userUuid: string, score: number}
    const sortedMap = new Map([...userMap.entries()].sort((a, b) => b[1] - a[1]));
    const sortedArray = Array.from(sortedMap, ([userUuid, score]) => ({ userUuid, score }));

    return sortedArray;
  }


  public onTapTopButton(){
    const topUsers = this.getTopUsers();

    console.log(topUsers);

    const mappedUsers = topUsers.map((user) => {
      const active = this.ongoingClassroom?.activeStudents.find((student) => student.userUuid == user.userUuid);
      return {
        userName: active?.nickname ?? "Ismeretlen",
        score: user.score
      }
    });
   
    this.dialogService.open(TopUsersDialogComponent, {
      data: {
        topStudents: mappedUsers,
      },
      scrollStrategy: new NoopScrollStrategy()
    });
  }

  public async ngOnInit():Promise<void> {
    if(this.ongoingClassroom === null || this.currentQuizSession === null) {
      await this.navigateBack();
      return;
    }

    this.currentQuizSessionChangeSubscription = this.ongoingClassroomService.getCurrentQuizSessionChangeSubscription(
      async (newState:{quizSession: QuizSession|null, event:QuizStateChangeEvent }) => {
        this.currentQuizSession = newState.quizSession;

        if(this.currentQuizSession === null) {
          await this.navigateBack();
        }
      }
    );

    this.numberOfAnswers = getNumberOfAnswersInCurrentQuiz(this.ongoingClassroom);

    this.studentAnsweredSubscription = this.ongoingClassroomService.getStudentAnsweredSubscription(
      (value:{ studentUuid:string }) => {
        this.numberOfAnswers = getNumberOfAnswersInCurrentQuiz(this.ongoingClassroom!);
      }
    );

    this.activeStundetsInQuizUpdatedSubscription = this.ongoingClassroomService.getActiveStundetsInQuizUpdatedSubscription(
      (activeStudents:Array<ClassMember>) => {
        this.numberOfAnswers = getNumberOfAnswersInCurrentQuiz(this.ongoingClassroom!);
      }
    );

    this.quizStateChangeSubscription = this.ongoingClassroomService.getCurrentQuizSessionChangeSubscription(
      (newState:{ quizSession:QuizSession|null, event:QuizStateChangeEvent }) => {
        if(newState.event === QuizStateChangeEvent.QuizStarted || newState.event === QuizStateChangeEvent.NextQuestion) {
          this.numberOfAnswers = getNumberOfAnswersInCurrentQuiz(this.ongoingClassroom!);
        }
      }
    );
  }

  public ngOnDestroy():void {
    this.studentAnsweredSubscription?.unsubscribe();
    this.activeStundetsInQuizUpdatedSubscription?.unsubscribe();
    this.quizStateChangeSubscription?.unsubscribe();

    // Close the active students dialog if it's open
    this.activeStudentsDialogRef?.close();
  }

  public async moveToNextQuestion():Promise<void> {
    if(this.isActionButtonDisabled) {
      return;
    }

    this.isActionButtonLoads = true;

    try {
      const nextQuestionIndex = await this.ongoingClassroomService.nextQuestionInQuiz();
      if(nextQuestionIndex === null) {
        await this.navigateBack();
      } else {
        this.disableActionButtonFor(1000);
      }
    } catch(error:any) {
      if(error.error === "NOT_RUNNING_QUIZ_SESSION") {
        this.snackBarService.open("A kvíz már nem létezik.", "Bezár", { duration: 3000 });
        await this.navigateBack();
      }
    }
    this.isActionButtonLoads = false;
    
  }

  public async evaulateQuestion():Promise<void> {
    if(this.isActionButtonDisabled) {
      return;
    }

    this.isActionButtonLoads = true;

    try {
      await this.ongoingClassroomService.evalCurrentQuestionInQuiz();
    } catch(error:any) {
      if(error.error === "NOT_RUNNING_QUIZ_SESSION") {
        this.snackBarService.open("A kvíz már nem létezik.", "Bezár", { duration: 3000 });
        await this.navigateBack();
      }
    }
    
    this.isActionButtonLoads = false;
    this.disableActionButtonFor(1000);
  }

  public async abortQuizSession():Promise<void> {
    this.isAbortQuizButtonLoads = true;

    try {
      await this.ongoingClassroomService.abortQuizSession();
    } catch(error:any) {}

    this.isAbortQuizButtonLoads = false;
    await this.navigateBack();
  }

  public async navigateBack():Promise<void> {
    await this.routerService.navigate([".."], { relativeTo: this.activatedRoute });
  }

  public openStudentListDialog():void {
    const ongoingClassroom:OngoingClassroom|null = this.ongoingClassroomService.getOngoingClassroomRef();
    if(ongoingClassroom) {
       this.activeStudentsDialogRef = this.dialogService.open(
        ActiveStudentsDialogComponent,
        {
          data: {
            ongoingClassroom: this.ongoingClassroomService.getOngoingClassroomRef()
          },
          scrollStrategy: new NoopScrollStrategy(),
          hasBackdrop: true,
          backdropClass: "invisible-backdrop"
        }
      );

      const afterDialogClose:Subscription = this.activeStudentsDialogRef.afterClosed().subscribe(
        () => {
          afterDialogClose.unsubscribe();
          this.activeStudentsDialogRef = null;
        }
      )
    }
  }

  private disableActionButtonFor(ms:number):void {
    this.isActionButtonDisabled = true;
    setTimeout(() => {
      this.isActionButtonDisabled = false;
    }, ms);
  }

}
