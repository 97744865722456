import { Injectable } from '@angular/core';
import { ClassMember, Classroom, getOptionPercentageFromAnswers, QuizSession, QuizSessionQuestion, QuizSessionQuestionAnwser, QuizSessionSettings, quizSessionSorter } from '../models/classroom';
import { SocketService } from './common/socket.service';
import { OngoingClassroom } from '../models/classroom';
import { PreviousClassroomService } from './previous-classroom.service';
import { Subject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { copyArrayToEnd } from '../functions/array-functions';

type ClassMemeberDescription = { userUuid:string, firstName:string, lastName:string, profileImageUrl:string };
export enum QuizStateChangeEvent {
  QuizStarted, QuestionEvaulated, NextQuestion, QuizEnded, Unknown
}


@Injectable({
  providedIn: 'root'
})
export class OngoingClassroomService {
  private ongoingClassroom:OngoingClassroom|null = null;

  private ongoingClassroomStateChangeSubject:Subject<OngoingClassroom|null>;
  private currentQuizSessionStateChangeSubject:Subject<{ quizSession:QuizSession|null, event:QuizStateChangeEvent }>;
  private optionAnswerCountChangesSubject:Subject<{optionIndex:number, percentage:number}>;
  private activeStudentsUpdatedSubject:Subject<Array<ClassMember>>;
  private activeStudentsInQuizUpdatedSubject:Subject<Array<ClassMember>>;
  private studentAnsweredSubject:Subject<{ studentUuid:string }>;

  constructor(
    private socketService:SocketService,
    private previousClassroomService:PreviousClassroomService,
    private snackBarService:MatSnackBar
  ) {
    this.ongoingClassroomStateChangeSubject = new Subject<OngoingClassroom|null>();
    this.currentQuizSessionStateChangeSubject = new Subject<{ quizSession:QuizSession|null, event:QuizStateChangeEvent }>();
    this.optionAnswerCountChangesSubject = new Subject<{optionIndex:number, percentage:number}>();
    this.activeStudentsUpdatedSubject = new Subject<Array<ClassMember>>();
    this.activeStudentsInQuizUpdatedSubject = new Subject<Array<ClassMember>>();
    this.studentAnsweredSubject = new Subject<{ studentUuid:string }>();
  }

  public getOngoingClassroomRef():OngoingClassroom|null {
    return this.ongoingClassroom;
  }

  public getOngoingClassroomChangeSubscription(next:(newState:OngoingClassroom|null) => void):Subscription {
    return this.ongoingClassroomStateChangeSubject.subscribe(next);
  }

  public async getRemoteOngoingClassroom():Promise<OngoingClassroom|null> {
    this.addEventListenersToSocket();
    
    try {
      const response:{ classRoom:OngoingClassroom|null } = await this.socketService.emit("getActiveClassRoom");

      if(response.classRoom) {
        response.classRoom.quizSessionHistory.sort(quizSessionSorter);
        this.ongoingClassroom = new OngoingClassroom(
          response.classRoom.uuid,
          response.classRoom.accessId,
          response.classRoom.created,
          this.createClassMermberList(response.classRoom.visitors as any as Array<ClassMemeberDescription>),
          response.classRoom.quizSessionHistory,
          this.createClassMermberList(response.classRoom.activeStudents as any as Array<ClassMemeberDescription>),
          this.createClassMermberList(response.classRoom.activeStudentsInQuiz as any as Array<ClassMemeberDescription>),
          response.classRoom.currentQuizSession
        );
      } else {
        this.ongoingClassroom = null;
      }

      this.ongoingClassroomStateChangeSubject.next(this.ongoingClassroom);
      this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom?.currentQuizSession ?? null, event: QuizStateChangeEvent.Unknown });
    } catch(error:any) {
      console.error(error);
      throw({ displayedMessage: "Hiba a folyamatban lévő óra lekérdezése közben!" });
    }
    
    return this.ongoingClassroom;

  }

  public async createClassroom():Promise<OngoingClassroom> {
    let ongoingClassroom:OngoingClassroom;
    try {
      // Could throw ALREADY_OPEN_ROOM error
      const response:{ classroom:OngoingClassroom } = await this.socketService.emit("createClassRoom");
      this.ongoingClassroom = this.createLocalClassroom(response.classroom);;
    } catch(error:any) {
      throw error;
    }

    return ongoingClassroom!;
  }

  /**
   * A helper function to create a classroom entity locally.
  */
  private createLocalClassroom(classroom:OngoingClassroom):OngoingClassroom {
    this.ongoingClassroom = new OngoingClassroom(classroom.uuid, classroom.accessId, classroom.created);
    this.ongoingClassroomStateChangeSubject.next(this.ongoingClassroom);
    return this.ongoingClassroom;
  }

  public async closeClassroom():Promise<void> {
    if(!this.ongoingClassroom) {
      return;
    }

    try {
      // Could throw PERMISSION_DENIED or NOT_EXIST_SESSION error
      const response:{ classroom:Classroom } = await this.socketService.emit("closeClassRoom", { classroomAccessId: this.ongoingClassroom.accessId });
      this.closeLocalClassroom(response.classroom);
    } catch(error:any) {
      if(error.error !== "NOT_EXIST_SESSION") {
        throw error;
      }

      this.closeLocalClassroom();
    }
    
  }

  private closeLocalClassroom(savedClassroom?:Classroom):void {
    if(!this.ongoingClassroom) {
      return;
    }

    this.ongoingClassroom = null;
    this.ongoingClassroomStateChangeSubject.next(null);
    if(savedClassroom) {
      this.previousClassroomService.addPreviousClassroom(savedClassroom);
    }
  }

  public getCurrentQuizSessionChangeSubscription(next:(newState:{ quizSession:QuizSession|null, event:QuizStateChangeEvent }) => void):Subscription {
    return this.currentQuizSessionStateChangeSubject.subscribe(next);
  }

  public async startQuizSession(quizSessionSettings:QuizSessionSettings):Promise<void> {
    if(this.ongoingClassroom === null) {
      return;
    }

    let quizSession:QuizSession|null = null;

    try {
      const response:{ quizSession:QuizSession } = await this.socketService.emit(
        "startQuizSession",
        { quizSessionSettings: quizSessionSettings }
      );
      
      quizSession = response.quizSession;

    } catch(error:any) {
      if(error.error === "ALREADY_RUNNING_QUIZ_SESSION" && error.quizSession) {
        quizSession = error.quizSession;
      }

      throw error;
    }
    
    if(quizSession) {
      this.ongoingClassroom.currentQuizSession = quizSession;
      this.ongoingClassroom.currentQuizSession.isActualQuestionEvaulated = false;
      this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: QuizStateChangeEvent.QuizStarted });
    } else {
      throw Error("QUIZ_START_ERROR");
    }
  }

  public async abortQuizSession():Promise<void> {
    if(this.ongoingClassroom === null || this.ongoingClassroom.currentQuizSession === null) {
      return;
    }

    try {
      const response:{ quizSession:QuizSession } = await this.socketService.emit("abortQuizSession", { quizUuid: this.ongoingClassroom.currentQuizSession.uuid });
      this.ongoingClassroom.quizSessionHistory.unshift(response.quizSession);
      this.ongoingClassroom.currentQuizSession = null;
      this.currentQuizSessionStateChangeSubject.next({ quizSession: null, event: QuizStateChangeEvent.QuizEnded });
    } catch(error:any) {
      if(error === "QUIZ_STATE_MISMATCH" && error.quizSession) {
        this.ongoingClassroom.currentQuizSession = error.quizSession;
      }

      throw error;
    }
  }

  public async nextQuestionInQuiz():Promise<number|null> {
    if(this.ongoingClassroom === null || this.ongoingClassroom.currentQuizSession === null) {
      this.snackBarService.open("Kérdésléptetés hiba: nincs jelenleg aktív kvíz.", "Bezár", { duration: 3000, verticalPosition: "top" });
      throw { error: "NO_CURRENT_QUIZ" };
    }

    try {
      const response:{ result:string, currentQuestionIndex:number } = await this.socketService.emit(
        "nextQuestionInQuiz",
        {
          quizUuid: this.ongoingClassroom.currentQuizSession.uuid,
          actualQuestionIndex: this.ongoingClassroom.currentQuizSession.actualQuestionIndex
        }
      );
      if(response.result === "SESSION_CLOSED") {
        this.ongoingClassroom.quizSessionHistory.unshift(this.ongoingClassroom.currentQuizSession);
        this.ongoingClassroom.currentQuizSession = null;
        this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: QuizStateChangeEvent.QuizEnded });
      } else {
        this.ongoingClassroom.currentQuizSession.actualQuestionIndex = response.currentQuestionIndex;
        this.ongoingClassroom.currentQuizSession.isActualQuestionEvaulated = false;
        this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: QuizStateChangeEvent.NextQuestion });
      }
    } catch(error:any) {
      if(error.error === "QUIZ_STATE_MISMATCH" && error.quizSession) {
        this.ongoingClassroom.currentQuizSession = error.quizSession;
        this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: QuizStateChangeEvent.Unknown });
      }

      throw error;
    }

    return this.ongoingClassroom.currentQuizSession?.actualQuestionIndex ?? null;
  }

  public async evalCurrentQuestionInQuiz():Promise<void> {
    if(this.ongoingClassroom === null || this.ongoingClassroom.currentQuizSession === null) {
      this.snackBarService.open("Kiértékelés hiba: nincs jelenleg aktív kvíz.", "Bezár", { duration: 3000, verticalPosition: "top" });
      return;
    }

    try {
      await this.socketService.emit(
        "evalCurrentQuestionInQuiz",
        {
          quizUuid: this.ongoingClassroom.currentQuizSession.uuid,
          actualQuestionIndex: this.ongoingClassroom.currentQuizSession.actualQuestionIndex
        }
      );
      this.ongoingClassroom.currentQuizSession.isActualQuestionEvaulated = true;
      this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: QuizStateChangeEvent.QuestionEvaulated });
    } catch(error:any) {
      console.error(error);
      if(error.error === "QUIZ_STATE_MISMATCH" && error.quizSession) {
        this.ongoingClassroom.currentQuizSession = error.quizSession;
        this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: QuizStateChangeEvent.Unknown });
      }

      throw error;
    }
  }
  
  public getOptionAnswerCountChangesSubscription(next:(newPercentage:{optionIndex:number, percentage:number}) => void):Subscription {
    return this.optionAnswerCountChangesSubject.subscribe(next);
  }

  public getActiveStundetsUpdatedSubscription(next:(updatedActiveStudents:Array<ClassMember>) => void):Subscription {
    return this.activeStudentsUpdatedSubject.subscribe(next);
  }

  public getActiveStundetsInQuizUpdatedSubscription(next:(updatedActiveStudentsInQuiz:Array<ClassMember>) => void):Subscription {
    return this.activeStudentsInQuizUpdatedSubject.subscribe(next);
  }

  public getStudentAnsweredSubscription(next:(value:{ studentUuid:string }) => void):Subscription {
    return this.studentAnsweredSubject.subscribe(next);
  }
 
  private addEventListenersToSocket():void {
    // Classroom events
    this.socketService.addOnHandlerToSocket("newClassroomCreated", this.onnNewClassroomCreatedHandler);
    this.socketService.addOnHandlerToSocket("classroomClosed", this.onClassroomClosedHandler);

    // Quiz events
    this.socketService.addOnHandlerToSocket("quizSessionStarted", this.onQuizSessionStartedHandler);
    this.socketService.addOnHandlerToSocket("quizSessionStateChange", this.onQuizSessionStateChangeHandler);
    this.socketService.addOnHandlerToSocket("closedQuizSession", this.onClosedQuizSessionHandler);

    // User events
    this.socketService.addOnHandlerToSocket("userAnswered", this.onUserAnsweredHandler);
    this.socketService.addOnHandlerToSocket("updateActiveUserList", this.onUpdateActiveUserListHandler);
    this.socketService.addOnHandlerToSocket("updateActiveUserListInQuiz", this.onUpdateActiveUserListInQuizHandler);
  }

  // Socket event handlers

  private onnNewClassroomCreatedHandler:(message:{ classroom:OngoingClassroom }) => void =
  (message:{ classroom:OngoingClassroom }) => {
    this.createLocalClassroom(message.classroom);
  };

  private onUserAnsweredHandler:(userAnswer:QuizSessionUserAnswer) => void =
  (userAnswer:QuizSessionUserAnswer) => {
    if(this.ongoingClassroom?.currentQuizSession?.uuid !== userAnswer.quizSessionUuid) {
      console.warn(`A user answer is received, but the quiz uuid hasn't matched the actual one. (was: ${userAnswer.quizSessionUuid})`);
      return;
    }

    const quizSessionQuestion:QuizSessionQuestion|null = this.ongoingClassroom.currentQuizSession.questions.find(question => question.question.uuid === userAnswer.questionUuid) ?? null;
    if(quizSessionQuestion === null) {
      console.warn(`A user answer is received, but the question uuid hasn't matched anything. (was: ${userAnswer.questionUuid})`);
      return;
    }

    const newQuizSessionQuestionAnswer:QuizSessionQuestionAnwser = {
      userUuid: userAnswer.userUuid,
      answerIndex: userAnswer.aswerIndex,
      correct: quizSessionQuestion.question.options.findIndex(option => option.isSolution) === userAnswer.aswerIndex,
      answerTimeInMs: userAnswer.answerTimeInMs
    };

    const index:number = quizSessionQuestion.answers.findIndex(
      quizSessionQuestionAnwser => quizSessionQuestionAnwser.userUuid === userAnswer.userUuid
    );

    if(index < 0) {
      quizSessionQuestion.answers.push(newQuizSessionQuestionAnswer);
    } else {
      quizSessionQuestion.answers[index] = newQuizSessionQuestionAnswer;
    }

    this.studentAnsweredSubject.next({ studentUuid: userAnswer.userUuid });

    for(let index = 0; index < quizSessionQuestion.question.options.length; ++index) {
      this.optionAnswerCountChangesSubject.next({
        optionIndex: index,
        percentage: getOptionPercentageFromAnswers(quizSessionQuestion.answers, index) 
      });
    }
    
  }

  private onUpdateActiveUserListHandler:(message:{ students:Array<ClassMemeberDescription>, visitors:Array<ClassMemeberDescription> }) => void =
  (message:{ students:Array<ClassMemeberDescription>, visitors:Array<ClassMemeberDescription> }) => {
    if(this.ongoingClassroom === null) {
      console.warn(`Update user list received, but the ongoing classroom was null.`);
      return;
    }

    this.ongoingClassroom.activeStudents.length = 0;
    copyArrayToEnd(this.ongoingClassroom.activeStudents, this.createClassMermberList(message.students));

    this.ongoingClassroom.visitors.length = 0;
    copyArrayToEnd(this.ongoingClassroom.visitors, this.createClassMermberList(message.visitors));

    this.activeStudentsUpdatedSubject.next(this.ongoingClassroom.activeStudents);
  }

  private onUpdateActiveUserListInQuizHandler:(message:{ students:Array<ClassMemeberDescription> }) => void =
  (message:{ students:Array<ClassMemeberDescription> }) => {
    if(this.ongoingClassroom === null || this.ongoingClassroom.currentQuizSession === null) {
      console.warn(`Update user in quiz list received, but the ongoing classroom or the current quiz session was null.`);
      return;
    }

    this.ongoingClassroom.activeStudentsInQuiz.length = 0;
    this.ongoingClassroom.activeStudentsInQuiz.push(
      ...this.createClassMermberList(message.students)
    );
    this.activeStudentsInQuizUpdatedSubject.next(this.ongoingClassroom.activeStudentsInQuiz);
  }

  private onClassroomClosedHandler:(message:{ classroom:Classroom }) => void =
  (message:{ classroom:Classroom }) => {
    if(!this.ongoingClassroom || !message.classroom) {
      console.warn(
        `A 'classroomClosed' event was received, but couldn't close the classroom.
        Local ongoing classroom uuid: ${this.ongoingClassroom?.uuid}
        Received uuid: ${message.classroom.uuid}`
      );
      return;
    }

    if(this.ongoingClassroom.uuid === message.classroom.uuid) {
      this.closeLocalClassroom(message.classroom);
    }
  }

  private onQuizSessionStartedHandler:(message:{ quizSession:QuizSession }) => void =
  (message:{ quizSession:QuizSession }) => {
    if(this.ongoingClassroom === null) {
      console.warn(` A 'quizSessionStarted' event was received, but the local ongoingClassoom was null.`);
      return;
    }

    this.ongoingClassroom.currentQuizSession = message.quizSession;
    this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: QuizStateChangeEvent.QuizStarted });
  }

  private onQuizSessionStateChangeHandler:(message:{ quizSession:QuizSession }) => void =
  (message:{ quizSession:QuizSession }) => {
    if(this.ongoingClassroom === null) {
      console.warn(`A 'quizSessionStateChange' event was received but the ongoingClassroom was null`);
      return;
    }

    this.ongoingClassroom.currentQuizSession = message.quizSession;
    this.currentQuizSessionStateChangeSubject.next({ quizSession: this.ongoingClassroom.currentQuizSession, event: message.quizSession.isActualQuestionEvaulated ? QuizStateChangeEvent.QuestionEvaulated : QuizStateChangeEvent.NextQuestion });
  }

  private onClosedQuizSessionHandler:(message:{ quizSession:QuizSession }) => void = 
  (message:{ quizSession:QuizSession }) => {
    if(this.ongoingClassroom === null) {
      console.warn(`A 'closedQuizSession' event was received but the ongoingClassroom was null`);
      return;
    }

    this.ongoingClassroom.currentQuizSession = null;
    this.currentQuizSessionStateChangeSubject.next({ quizSession: null, event: QuizStateChangeEvent.QuizEnded });

    if(this.ongoingClassroom.quizSessionHistory.find(quizSession => quizSession.uuid === message.quizSession.uuid) === undefined) {
      this.ongoingClassroom.quizSessionHistory.unshift(message.quizSession);
    }

  }


  // Helper functions

  private createClassMermberList(responseDescription:Array<ClassMemeberDescription>):Array<ClassMember> {
    return (responseDescription ?? []).map(
      (student:ClassMemeberDescription) => {
        return {
          userUuid: student.userUuid,
          nickname: [ student.lastName ?? "", student.firstName ?? "" ].filter(nameFragment => nameFragment !== "").join(" ") ?? "Ismeretlen",
          profileImageUrl: student.profileImageUrl
        }
      }
    );
  }

  public async refreshQuizSessionHistories():Promise<void> {
    if(this.ongoingClassroom === null) {
      throw("No classroom");
    }
    
    try {
      const response:{ histories:Array<QuizSession> } = await this.socketService.emit("getQuizSessionHistoriesOfCurrentClassroom");
      this.ongoingClassroom.quizSessionHistory.length = 0;
      copyArrayToEnd(this.ongoingClassroom.quizSessionHistory, response.histories);
      this.ongoingClassroom.quizSessionHistory.sort(quizSessionSorter);
    } catch(error:any) {
      console.error(error);
    }
  }

  public clearChachedData():void {
    this.ongoingClassroom = null;
  }

}

class QuizSessionUserAnswer {
  userUuid:string;
  quizSessionUuid:string;
  questionUuid:string;
  aswerIndex: number;
  answerTimeInMs:number;
}