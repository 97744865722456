import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from './backend.service'
import { SessionService, UserSession } from './session.service';
import { sha256 } from 'js-sha256';
import { HttpResponseData } from 'src/app/models/common/http';
import { SocketService } from './socket.service';
import { OngoingClassroomService } from '../ongoing-classroom.service';
import { PreviousClassroomService } from '../previous-classroom.service';
import { QuestionGroupService } from '../question-group.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static readonly initialLoggedInRoute:string = "dashboard/classrooms";

  constructor(
    private sessionService:SessionService,
    private backendService:BackendService,
    private socketService:SocketService,
    private router:Router,
    private ongoingClassroomService:OngoingClassroomService,
    private previousClassroomService:PreviousClassroomService,
    private questionGroupService:QuestionGroupService
  ) { }

  public async login(email:string, password:string):Promise<LoginResult> {
    let loginResult:LoginResult;
    email += !email.includes('@') ? "@gmail.com" : "";
    const httpBody:Object = { credentials: { email: email, password: sha256(password) } };

    try {
      const response:HttpResponseData<UserSession, AuthenticationResponseString> = await this.backendService.callApi("/api/instructor/auth/get_token", "POST", httpBody);
      switch(response.result) {
        case "ACCESS_GRANTED":
          loginResult = LoginResult.Success;
          this.sessionService.createLocalSession(response.data);
          await this.router.navigate([ AuthenticationService.initialLoggedInRoute ]);
          break;
        case "ACCESS_DENIED":
          loginResult = LoginResult.WrongCredentials;
          break;
        default:
          loginResult = LoginResult.ServerError;
      }
    } catch(error:any) {
      if(error.error.error) {
        loginResult = LoginResult.ServerError;
      } else {
        loginResult = LoginResult.ServerConnectionError;
      }
    }

    return loginResult;
  }

  public async logout():Promise<void> {
    this.sessionService.deleteLocalSession();
    this.socketService.disconnectSocket();
    this.clearCachedDatas();
    await this.router.navigate(["login"]);
    console.info("Socket disconnected and logged out.");
  }

  private clearCachedDatas():void {
    this.ongoingClassroomService.clearChachedData();
    this.previousClassroomService.clearCahcedData();
    this.questionGroupService.clearCachedData();
  }
}

export type AuthenticationResponseString = "ACCESS_GRANTED"|"ACCESS_DENIED";

export enum LoginResult {
  Success, WrongCredentials, ServerError, ServerConnectionError
}
