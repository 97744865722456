export function getFirstElementFromArrayByPredicate<ArrayType>(array:ReadonlyArray<ArrayType>, predicate:(element:ArrayType) => boolean):ArrayType|null {
    const index:number = array.findIndex(predicate);
    return index >= 0 ? array[index] : null;
}

export function removeFirstElementFromArrayByPredicate<ArrayType>(array:Array<ArrayType>, predicate:(element:ArrayType) => boolean):boolean {
    const index:number = array.findIndex(predicate);
    
    if(index < 0) {
        return false;
    }

    array.splice(index, 1);
    return true;
}

export function reduceToSum(number1:number, number2:number):number {
    return number1 + number2;
}

export function copyArrayToEnd<StoredType>(targetArray:Array<StoredType>, sourceArray:ReadonlyArray<StoredType> = []):void {
    sourceArray.forEach(
        (element:StoredType) => {
            targetArray.push(element);
        }
    );
}