import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OngoingClassroom } from 'src/app/models/classroom';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';

@Component({
  selector: 'app-ongoing-classroom',
  templateUrl: './ongoing-classroom.component.html',
  styleUrls: ['./ongoing-classroom.component.scss']
})
export class OngoingClassroomComponent implements OnInit {

  ongoingClassroom:OngoingClassroom;
  ongoingClassroomChangeSubscription:Subscription;

  isCloseClassroomButtonLoading:boolean = false;



  constructor(
    private ongoingClassroomService:OngoingClassroomService,
    private routerService:Router,
    private snackBarService:MatSnackBar
  ) { }

  public async ngOnInit():Promise<void> {
    const ongoingClassroom:OngoingClassroom|null = this.ongoingClassroomService.getOngoingClassroomRef();

    this.ongoingClassroomChangeSubscription = this.ongoingClassroomService.getOngoingClassroomChangeSubscription(
      (ongoingClassroom:OngoingClassroom|null) => {
        if(ongoingClassroom === null) {
          this.routerService.navigate([".."]);
          return;
        }

        this.ongoingClassroom = ongoingClassroom;
      }
    );

    if(ongoingClassroom === null) {
      await this.routerService.navigate([".."]);
      return;
    }

    this.ongoingClassroom = ongoingClassroom;
  }

  public async closeClassroom():Promise<void> {
    this.isCloseClassroomButtonLoading = true;
    try {
      await this.ongoingClassroomService.closeClassroom();
      await this.routerService.navigate(["/dashboard/classrooms"]);
    } catch(error:any) {
      this.snackBarService.open("Hiba történt az óra lezárásakor!", "Bezár", { duration: 3000 });
    }
    this.isCloseClassroomButtonLoading = false;
  }
}
