import { Component, Input, OnInit } from '@angular/core';
import { Classroom } from 'src/app/models/classroom';

@Component({
  selector: 'app-previous-classroom-card',
  templateUrl: './previous-classroom-card.component.html',
  styleUrls: ['./previous-classroom-card.component.scss']
})
export class PreviousClassroomCardComponent implements OnInit {
  @Input() classroom:Classroom;

  constructor() { }

  ngOnInit(): void {
  }

}
