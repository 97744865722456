<div class = header-bar>
    <a href = "dashboard" class = "dashboard-link">
        <div class = "icon">
            <div>
                <img src = "assets/images/app-logo.png" width = "40" height = "40">
            </div>
            <div class = "name">
                <div class = "upper-name">
                    Mr. Kresz
                </div>
                <div class = "lower-name">
                    Classroom
                </div>
            </div>
        </div>
    </a>
    <div *ngIf = "mobileMode" class = "hamburger-container">
        <button class = "hamburger hamburger--squeeze" [ngClass] = "isMenuOpen ? 'is-active' : ''" type = "button" (click) = "toogleMenu()">
            <span class = "hamburger-box">
                <span class = "hamburger-inner"></span>
            </span>
        </button>
    </div>
    <div *ngIf = "!mobileMode" class = "profile" [matMenuTriggerFor] = "profileMenu">
        <div class = "profile-name">
            {{ username }}
        </div>
        <div>
            <img [src] = "userProfileImageUrl ? userProfileImageUrl : 'assets/defaultprofile.png'" width = "40" height = "40">
        </div>
    </div>
</div>

<mat-menu #profileMenu = "matMenu" xPosition = "before" style = "z-index: 1">
    <app-header-menu [mobileMode] = "mobileMode"></app-header-menu>
</mat-menu>

<div *ngIf = "mobileMode && isMenuOpen" (menuItemClicked) = "menuItemClicked()">
    <app-header-menu
        [mobileMode] = "mobileMode"
        [username] = "username"
        [userProfileImageUrl] = "userProfileImageUrl"
    ></app-header-menu>
</div>


