import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClassMember, getNumberOfAnswersInCurrentQuiz, OngoingClassroom, QuizSession } from 'src/app/models/classroom';
import { OngoingClassroomService, QuizStateChangeEvent } from 'src/app/services/ongoing-classroom.service';

@Component({
  selector: 'app-student-lists-container',
  templateUrl: './student-lists-container.component.html',
  styleUrls: ['./student-lists-container.component.scss']
})
export class StudentListsContainerComponent implements OnInit, OnDestroy {
  @Input() isThereCurrentQuizSession:boolean;
  @Input() ongoingClassroom:OngoingClassroom;

  @Output() numberOfAnswersChangeEvent:EventEmitter<number> = new EventEmitter<number>();

  studentAnsweredSubscription:Subscription;
  quizStateChangeSubscription:Subscription;
  activeStundetsUpdatedSubscription:Subscription;
  activeStundetsInQuizUpdatedSubscription:Subscription;

  secondaryStudentListInQuiz:Array<ClassMember> = [];

  constructor(
    private ongoingClassroomService:OngoingClassroomService
  ) { }

  ngOnInit(): void {
    this.studentAnsweredSubscription = this.ongoingClassroomService.getStudentAnsweredSubscription(
      (value:{ studentUuid:string }) => {
        this.numberOfAnswersChangeEvent.emit(getNumberOfAnswersInCurrentQuiz(this.ongoingClassroom));
      }
    );

    this.updateSecondaryStudentListInQuiz();

    this.quizStateChangeSubscription = this.ongoingClassroomService.getCurrentQuizSessionChangeSubscription(
      (newState:{ quizSession:QuizSession|null, event:QuizStateChangeEvent }) => {
        if(newState.event === QuizStateChangeEvent.QuizStarted || newState.event === QuizStateChangeEvent.NextQuestion) {
          this.numberOfAnswersChangeEvent.emit(0);
          this.updateSecondaryStudentListInQuiz();
        }
      }
    );

    this.activeStundetsUpdatedSubscription = this.ongoingClassroomService.getActiveStundetsUpdatedSubscription(
      (activeStudents:Array<ClassMember>) => {
        this.updateSecondaryStudentListInQuiz();
      }
    );

    this.activeStundetsInQuizUpdatedSubscription = this.ongoingClassroomService.getActiveStundetsInQuizUpdatedSubscription(
      (activeStudents:Array<ClassMember>) => {
        this.numberOfAnswersChangeEvent.emit(getNumberOfAnswersInCurrentQuiz(this.ongoingClassroom));
        this.updateSecondaryStudentListInQuiz();
      }
    );
  }

  public ngOnDestroy():void {
    this.studentAnsweredSubscription?.unsubscribe();
    this.quizStateChangeSubscription?.unsubscribe();
    this.activeStundetsUpdatedSubscription?.unsubscribe();
    this.activeStundetsInQuizUpdatedSubscription?.unsubscribe();
  }

  private updateSecondaryStudentListInQuiz():void {
    this.secondaryStudentListInQuiz = this.ongoingClassroom.visitors.filter(
      (student:ClassMember) => {
        if(this.ongoingClassroom.activeStudents.find(activeStudent => activeStudent.userUuid === student.userUuid) !== undefined) {
          return !this.ongoingClassroom.activeStudentsInQuiz.find(activeStudentInQuiz => activeStudentInQuiz.userUuid === student.userUuid);
        } else {
          return this.ongoingClassroom.getCurrentQuestion()?.answers.find(userAnswer => userAnswer.userUuid === student.userUuid);
        }
      }
    );
  }

}
