<button
    [disabled] = "isLoading"
    [class.button-loading] = "isLoading"
>
    <div>
        <span class = "material-icons"> add_circle </span>
    </div>
    <div>
        Új óra létrehozása
    </div>
</button>
