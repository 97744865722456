<app-fancy-card [headerClass] = "'mrkresz-gray-background'">
    <div class = "card-header" card-header>
        <div class = "question-number">
            {{ questionIndex + 1 }}.
        </div>
        <div class = "question-text">
            {{ quizSessionQuestion.question.text }}
        </div>
    </div>
    <div class = "card-content" card-content>
        <div class = "question-data" [class.has-media] = "quizSessionQuestion.question.mediaUrl">
            <div class = "media-container">
                <img
                    *ngIf = "quizSessionQuestion.question.mediaUrl"
                    [src] = "quizSessionQuestion.question.mediaUrl"
                    class = "media"
                />
            </div>

            <div class = "options-container">
                <div
                    *ngFor = "let option of quizSessionQuestion.question.options; let i = index"
                    class = "option"   
                >
                    <div class = "letter-container mrkresz-gradient-background">
                        {{ String.fromCharCode('A'.charCodeAt(0) + i) }}
                    </div>
                    <div
                        class = "option-text"
                        [class.is-solution] = "option.isSolution"
                    >
                        {{ option.text }}
                    </div>
                </div>
            </div>
        </div>
        <div class = "card-footer">
            <div class = "footer-item">
                <div class = "answers-container">
                    <div class = "answer-type-container">
                        <span class = "material-icons" style = "color: lightgreen; vertical-align: bottom;">
                            check_circle
                        </span>
                        <span>
                            {{ numberOfCorrectAnswers }} db
                        </span>
                    </div>
                    <div class = "answer-type-container">
                        <span class = "material-icons" style = "color: lightcoral; vertical-align: bottom;">
                            highlight_off
                        </span>
                        <span>
                            {{ numberOfIncorrectAnswers }} db
                        </span>
                    </div>
                </div>
            </div>
            <div class = "show-question-button-container footer-item">
                <a [routerLink] = "'../review'" [state] = "{ questionIndex: questionIndex }">
                    <button
                        class = "gradient-button show-question-button "
                    >
                        Megtekintés
                    </button>
                </a>
                
            </div>
        </div>
    </div>
</app-fancy-card>






<!-- <div class = "main-container">
    <div class = "header-item header-number" style = "grid-area: 'number'; text-align: center;">
        {{ questionIndex + 1 }}.
    </div>
    <div class = "header-item" style = "grid-area: 'question-text';">
        {{ quizSessionQuestion.question.text }}    
    </div>
    <div style = "grid-area: 'media'; box-sizing: border-box;">
        <img
            *ngIf = "quizSessionQuestion.question.mediaUrl"
            [src] = "quizSessionQuestion.question.mediaUrl"
            style = "width: 100%; height: auto; border: 1px solid #F2F2F2; border-radius: 5px;"
        />
    </div>
    <div style = "grid-area: 'options';">
        <div
            *ngFor = "let option of quizSessionQuestion.question.options; let i = index"
            class = "option"   
        >
            <div class = "letter-container mrkresz-gradient-background">
                {{ String.fromCharCode('A'.charCodeAt(0) + i) }}
            </div>
            <div
                class = "option-text"
                [class.is-solution] = "option.isSolution"
            >
                {{ option.text }}
            </div>
        </div>
    </div>
    <div class = "answers">
        <div class = "answer-item">
            <span class = "material-icons" style = "color: lightgreen; vertical-align: bottom;">
                check_circle
            </span>
            <span class = "answers-count">
                {{ numberOfCorrectAnswers }}
            </span>
        </div>
        <div class = "answer-item">
            <span class = "material-icons" style = "color: lightcoral; vertical-align: bottom;">
                highlight_off
            </span>
            <span class = "answers-count">
                {{ numberOfIncorrectAnswers }}
            </span>
        </div>
    </div>
</div>
<a [routerLink] = "'../review'" [state] = "{ questionIndex: questionIndex }">
    <div class = "open-question-button">
        <span class = "material-icons" style="vertical-align: bottom;">
            open_in_full
        </span>
    </div>
</a> -->
