import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClassMember, getNumberOfAnswersInCurrentQuiz, OngoingClassroom } from 'src/app/models/classroom';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';

@Component({
  selector: 'app-active-students',
  templateUrl: './active-students.component.html',
  styleUrls: ['./active-students.component.scss']
})
export class ActiveStudentsComponent implements OnInit, OnDestroy {
  @Input() ongoingClassroom:OngoingClassroom;

  isExpanded:boolean = false;
  isThereCurrentQuizSession:boolean = false;
  numberOfAnswers:number = 0;
  quizStateChangeSubscription:Subscription;

  constructor(
    private ongoingClassroomService:OngoingClassroomService
  ) {}

  public ngOnInit():void {
    this.isThereCurrentQuizSession = this.ongoingClassroom.currentQuizSession !== null;
    this.numberOfAnswers = getNumberOfAnswersInCurrentQuiz(this.ongoingClassroom);

    this.quizStateChangeSubscription = this.ongoingClassroomService.getCurrentQuizSessionChangeSubscription(
      (newState:any) => {
        this.isThereCurrentQuizSession = newState.quizSession !== null;
      }
    );
  }

  public ngOnDestroy():void {
    this.quizStateChangeSubscription?.unsubscribe();
  }

  public handleNumberOfAnswersChangeEvent(eventValue:number):void {
    this.numberOfAnswers = eventValue;
  }

  public toogleExpansionStatus():void {
    this.isExpanded = !this.isExpanded;
  }
}
