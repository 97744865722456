import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {

  constructor(
    private snackBar:MatSnackBar,
    private injector:Injector
  ) { }

  public handleError(error:any, snackBarMessage:string) {
    const authenticationService:AuthenticationService = this.injector.get(AuthenticationService);

    if(error?.error?.error) {
      switch(error.error.error) {
        case ApiError.InvalidSessionError.name:
          this.snackBar.open(ApiError.InvalidSessionError.message, "Bezár", { duration: 3000 });
          authenticationService.logout();
          break;
        case ApiError.RequiredPermissionMissingError.name:
          this.snackBar.open(ApiError.RequiredPermissionMissingError.message, "Bezár", { duration: 3000 });
          break;
        default:
          this.snackBar.open(snackBarMessage, "Bezár", { duration: 3000, panelClass: ["mat-snackbar-fail"] });
      }
    } else {
      console.error(error);
      this.snackBar.open("Hiba a szerverkapcsolatban.", "Bezár", { duration: 3000 });
    }
    
    throw error;
  }
}

class ApiError {
  public static readonly InvalidSessionError:Error = {
    name: "INVALID_SESSION",
    message: "A munkamenet lejárt. Kérjük jelentkezz be újra."
  }

  public static readonly RequiredPermissionMissingError:Error = {
    name: "REQUIRED_PERMISSION_MISSING",
    message: "Nincs megfelelő jogosultságod a művelethez."
  }
}