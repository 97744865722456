<app-student-list
    *ngIf = "!isThereCurrentQuizSession"
    [students] = "ongoingClassroom.activeStudents"
    [messageOnEmptyList] = "'Nincs csatlakozott tanuló'"
></app-student-list>
<app-student-list
    *ngIf = "isThereCurrentQuizSession"
    [title] = "'Tanulók a kvízben'"
    [ongoingClassroom] = "ongoingClassroom"
    [students] = "ongoingClassroom.activeStudentsInQuiz"
    [messageOnEmptyList] = "'Nincs tanuló a kvízben'"
></app-student-list>
<app-student-list
    *ngIf = "isThereCurrentQuizSession"
    [title] = "'További tanulók'"
    [ongoingClassroom] = "ongoingClassroom"
    [students] = "secondaryStudentListInQuiz"
    [listOpacity] = "0.3"
    [messageOnEmptyList] = "'Nincsenek további tanulók'"
></app-student-list>
