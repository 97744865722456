import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { OngoingClassroom } from 'src/app/models/classroom';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';

@Component({
  selector: 'app-active-students-dialog',
  templateUrl: './active-students-dialog.component.html',
  styleUrls: ['./active-students-dialog.component.scss']
})
export class ActiveStudentsDialogComponent implements OnInit, OnDestroy {
  isThereCurrentQuizSession:boolean = false;
  quizStateChangeSubscription:Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { ongoingClassroom:OngoingClassroom },
    private ongoingClassroomService:OngoingClassroomService
  ) { }

  ngOnInit(): void {
    this.isThereCurrentQuizSession = this.data.ongoingClassroom.currentQuizSession !== null;

    this.quizStateChangeSubscription = this.ongoingClassroomService.getCurrentQuizSessionChangeSubscription(
      (newState:any) => {
        this.isThereCurrentQuizSession = newState.quizSession !== null;
      }
    );
  }

  public ngOnDestroy():void {
    this.quizStateChangeSubscription?.unsubscribe();
  }

}
