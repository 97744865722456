import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  onTouchStartHandler:(event:TouchEvent) => void;
  onTouchEndHandler:(event:TouchEvent) => void;

  constructor(
    private platform:Platform,
    private settingsService:SettingsService
  ) {}

  public ngOnInit():void {
    if(this.platform.IOS || this.platform.SAFARI) {
      this.disableDubleTapZoomOnSafari();
    }

    this.settingsService.loadSettingsFromLocalStorage();
  }

  public ngOnDestroy():void {
    document.removeEventListener('touchstart', this.onTouchStartHandler);
    document.removeEventListener('touchend', this.onTouchEndHandler);
  }

  // This function disables the double-tap zoom function in safari 10+
  private disableDubleTapZoomOnSafari():void {
    let preLastTouchStartAt:number = 0;
    let lastTouchStartAt:number = 0;
    const delay:number = 5;

    this.onTouchStartHandler = () => {
      preLastTouchStartAt = lastTouchStartAt;
      lastTouchStartAt = new Date().valueOf();
    };

    this.onTouchEndHandler = (event:TouchEvent) => {
      const touchEndAt = new Date().valueOf();
      if (touchEndAt - preLastTouchStartAt < delay) {
        event.preventDefault();
        (event.target as any)?.click?.();
      }
    };

    document.addEventListener('touchstart', this.onTouchStartHandler);
    document.addEventListener('touchend', this.onTouchEndHandler);
  }

}
