import { Component, Input, OnInit } from '@angular/core';
import { QuizGenerationRuleItem, QuizSession } from 'src/app/models/classroom';

@Component({
  selector: 'app-quiz-session-card',
  templateUrl: './quiz-session-card.component.html',
  styleUrls: ['./quiz-session-card.component.scss']
})
export class QuizSessionCardComponent implements OnInit {
  @Input() quizSession:QuizSession;
  @Input() number:number;

  questionGroups:string = "";

  constructor() { }

  ngOnInit(): void {
    this.questionGroups = this.quizSession.settings.generationRule.map(
      (generationRuleItem:QuizGenerationRuleItem) => {
        return `${generationRuleItem.questionGroupName ?? "Ismeretlen kérdéscsoport"} (${generationRuleItem.count}db)`;
      }
    ).join(", ");
  }

}
