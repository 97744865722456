import { Injectable } from '@angular/core';
import { SettingsString } from '../models/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private static readonly LocalStorageUserSettingsKey:string = "UserSettingsKey";
  private settingsMap:Map<SettingsString, any>;

  constructor() {
    this.settingsMap = new Map<SettingsString, any>();
  }

  public getSettingsValue(name:SettingsString, defaultValueIfNotFound?:any):any {
    return this.settingsMap.get(name) ?? defaultValueIfNotFound;
  }

  public setSettingsValue(name:SettingsString, value:any):void {
    this.settingsMap.set(name, value);
  }

  public saveSettingsToLocalStorage():void {
    const savedSettingsObject:Object = {};
    this.settingsMap.forEach(
      (value:any, key:SettingsString) => {
        (savedSettingsObject as any)[key] = value;
      }
    );
    localStorage.setItem(SettingsService.LocalStorageUserSettingsKey, JSON.stringify(savedSettingsObject))
  }

  public loadSettingsFromLocalStorage():void {
    try {
      let savedSettingsObjectString:string|null = localStorage.getItem(SettingsService.LocalStorageUserSettingsKey);
      if(!savedSettingsObjectString) {
        savedSettingsObjectString = "{}";
      }
      const savedSettingsObject:Object = JSON.parse(savedSettingsObjectString);
      for (const [key, value] of Object.entries(savedSettingsObject)) {
        this.settingsMap.set(key as SettingsString, value);
      }
    } catch(error:any) {
        console.error("Failed to load the saved settings.");
        console.error(error);
    }
  }

  public resetSettings():void {
    this.settingsMap.clear();
    localStorage.removeItem(SettingsService.LocalStorageUserSettingsKey);
  }
}
