<!-- Actual classroom section -->
<div class = "actual-classroom-section">
    <app-new-classroom-button
        *ngIf = "ongoingClassroom === null"
        (click) = "handleCreateClassroomButtonClick()"
        [isLoading] = "isCreateNewClassroomButtonLoading"
        matRipple
    ></app-new-classroom-button>
    
    <div *ngIf = "ongoingClassroom !== null" class = "actual-classroom">
        <app-ongoing-classroom-card
            [ongoingClassroom] = "ongoingClassroom"
        ></app-ongoing-classroom-card>
    </div>
</div>

<!-- Previous classrooms -->
<div class = "page-subtitle">
    Korábbi órák <span *ngIf = "!isPreviousClassroomsLoading"> ({{ previousClassrooms.length }}) </span>
</div>
<div *ngIf = "!isPreviousClassroomsLoading" class = "previous-classrooms-container">
    <a
        *ngFor = "let previousClassroom of previousClassrooms"
        [routerLink] = "'../previous/' + previousClassroom.uuid"
    >
        <app-previous-classroom-card
            [classroom] = "previousClassroom"
        ></app-previous-classroom-card>
    </a>
</div>
<div *ngIf = "!isPreviousClassroomsLoading && previousClassrooms.length === 0" class = "no-previous-classrooms">
    Még nem indítottál egyetlen órát sem.
</div>
<div *ngIf = "isPreviousClassroomsLoading" class = "previous-classrooms-loading-container">
    <mat-spinner></mat-spinner>
</div>
