import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';
import { OngoingClassroom } from 'src/app/models/classroom';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, Event as NavigationEvent } from '@angular/router';
import { SocketService } from 'src/app/services/common/socket.service';

@Component({
  selector: 'app-classrooms',
  templateUrl: './classrooms.component.html',
  styleUrls: ['./classrooms.component.scss']
})
export class ClassroomsComponent implements OnInit {

  isContentLoading:boolean;
  infoText:string;

  ongoingClassroom:OngoingClassroom|null;
  ongoingClassroomChangeSubscription:Subscription;

  shouldShowStudents:boolean = true;

  socketAuthenticatedSubscription:Subscription;

  constructor(
    private socketService:SocketService,
    private ongoingClassroomService:OngoingClassroomService,
    private snackBarService:MatSnackBar,
    private routerService:Router
  ) {}

  public async ngOnInit():Promise<void> {
    this.isContentLoading = true;

    this.routerService.events.subscribe(
      (event:NavigationEvent) => {
        if(event instanceof NavigationEnd) {
          this.shouldShowStudents = this.shoudShowStudnentsListOnRoute(event.url);
        }
      }
    );

    this.socketAuthenticatedSubscription = this.socketService.getSocketAuthenticatedSubscription(
      async () => {
        this.ongoingClassroom = await this.ongoingClassroomService.getRemoteOngoingClassroom();
      }
    );

    try {
      this.infoText = "Aktuális óra lekérdezése..."
      this.ongoingClassroom = await this.ongoingClassroomService.getRemoteOngoingClassroom();
    
      this.ongoingClassroomChangeSubscription = this.ongoingClassroomService.getOngoingClassroomChangeSubscription(
        (newState:OngoingClassroom|null) => {
          this.ongoingClassroom = newState;
        }
      );
    } catch(error:any) {
      if(error.displayedMessage) {
        this.snackBarService.open(error.displayedMessage, "Bezár", { duration: 3000 });
      }
    }

    this.shouldShowStudents = this.shoudShowStudnentsListOnRoute(this.routerService.url);

    this.isContentLoading = false;
  }

  private shoudShowStudnentsListOnRoute(url:string):boolean {
    return !url.includes("/quiz");
  }
  
}
