import { Injectable } from '@angular/core';

export class UserSession {
  uuid:string;
  username:string;
  email:string;
  sessionId:string;
  profileImageUrl:string;
  firstName?:string;
  lastName?:string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public static readonly LocalStorageUserUuidKey:string = "UserUuidKey";
  public static readonly LocalStorageUserNameKey:string = "UserNameKey";
  public static readonly LocalStorageUserEmailKey:string = "UserEmailKey";
  public static readonly LocalStorageUserSessionIdKey:string = "UserSessionIdKey";
  public static readonly LocalStorageUserProifleImageUrlKey:string = "UserProifleImageUrlKey";

  private userSession:UserSession|null = null;

  constructor() {
    this.userSession = this.createUserSessionFromSessionStorage();
  }

  public createLocalSession(userSession:UserSession):void {
    localStorage.setItem(SessionService.LocalStorageUserUuidKey, userSession.uuid);
    localStorage.setItem(
      SessionService.LocalStorageUserNameKey,
      [ userSession.lastName ?? "", userSession.firstName ?? "" ].filter(nameFragment => nameFragment !== "").join(" ")
    );
    localStorage.setItem(SessionService.LocalStorageUserEmailKey, userSession.email);
    localStorage.setItem(SessionService.LocalStorageUserSessionIdKey, userSession.sessionId ?? (userSession as any).sessionID);
    localStorage.setItem(SessionService.LocalStorageUserProifleImageUrlKey, userSession.profileImageUrl ?? "");

    this.userSession = this.createUserSessionFromSessionStorage();
  }

  public deleteLocalSession():void {
    localStorage.removeItem(SessionService.LocalStorageUserUuidKey);
    localStorage.removeItem(SessionService.LocalStorageUserNameKey);
    localStorage.removeItem(SessionService.LocalStorageUserEmailKey);
    localStorage.removeItem(SessionService.LocalStorageUserSessionIdKey);
    localStorage.removeItem(SessionService.LocalStorageUserProifleImageUrlKey);

    this.userSession = null;
  }

  public isThereLocalSession():boolean {
    return this.userSession !== null;
  }

  public getUuid():string|null {
    return this.userSession?.uuid ?? null;
  }

  public getUsername():string|null {
    return this.userSession?.username ?? null;
  }

  public getEmail():string|null {
    return this.userSession?.email ?? null;
  }

  public getSessionId():string|null {
    return this.userSession?.sessionId ?? null;
  }

  public getProfileImageUrl():string|null {
    return this.userSession?.profileImageUrl ?? null;
  }

  public getUserSession():Readonly<UserSession|null> {
    return this.userSession;
  }

  private createUserSessionFromSessionStorage():UserSession|null {
    if(this.isThereSavedSessionInSessionStorage()) {
      return {
        uuid:            localStorage.getItem(SessionService.LocalStorageUserUuidKey)!,
        username:        localStorage.getItem(SessionService.LocalStorageUserNameKey)!,
        email:           localStorage.getItem(SessionService.LocalStorageUserEmailKey)!,
        sessionId:       localStorage.getItem(SessionService.LocalStorageUserSessionIdKey)!,
        profileImageUrl: localStorage.getItem(SessionService.LocalStorageUserProifleImageUrlKey) ?? ""
      }
    } else {
      return null;
    }
  }

  private isThereSavedSessionInSessionStorage():boolean {
    return !!localStorage.getItem(SessionService.LocalStorageUserUuidKey) &&
           !!localStorage.getItem(SessionService.LocalStorageUserEmailKey) &&
           !!localStorage.getItem(SessionService.LocalStorageUserSessionIdKey);
  }

}
