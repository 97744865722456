<div class = "back-button-container">
    <button
        class = "quiz-action-button header-button back-button"
        (click) = "navigateBack()"
    >
        <span class = "material-icons">
            arrow_back
        </span>
        <span>
            Vissza
        </span>
    </button>
</div>
<div class = "page-title">
    Kérdések a kvízben
</div>
<app-quiz-session-summary-question-card
    *ngFor = "let quizSessionQuestion of quizSession.questions; let i = index"
    [quizSessionQuestion] = "quizSessionQuestion"
    [questionIndex] = "i"
></app-quiz-session-summary-question-card>
