import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-top-users-dialog',
  templateUrl: './top-users-dialog.component.html',
  styleUrls: ['./top-users-dialog.component.scss']
})
export class TopUsersDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {topStudents: {userName: string, score: number}[]}) { }

  ngOnInit(): void {
    
  }
}
