<div class = "back-button-container">
    <a [routerLink] = "'..'">
        <button
            class = "quiz-action-button header-button back-button"
            mat-ripple
        >
            <span class = "material-icons">
                arrow_back
            </span>
            <span>
                Vissza
            </span>
        </button>
    </a>
</div>

<div class = "page-title">
    Kvíz létrehozása
</div>

<div class = "content">
    <div class = "quiz-metadata">
        <span *ngIf = "selectedQuestionGroups.length === 0">
            Jelenleg nincs felvéve egyetlen kérdéscsoport sem.
        </span>
        <span *ngIf = "selectedQuestionGroups.length !== 0">
            Jelenleg {{ numberOfQuestionsInQuiz }} kérdés van a kvízben.
        </span>
    </div>
    
    <div *ngFor = "let selectedQuestionGroup of selectedQuestionGroups; let i = index" class = "selected-question-group-item">
        <div class = "number">
            {{ i + 1 }}.
        </div>
        <div class = "question-group-name">
            {{ selectedQuestionGroup.questionGroup.name }}
        </div>
        <div class = "question-group-question-count">
            <span style = "white-space: nowrap;"> {{ selectedQuestionGroup.count }} db </span>
        </div>
        <div  class = "delete-question-group-container">
            <span
                class = "delete-question-group"
                (click) = "deleteFromSelectedQuestionGroups(selectedQuestionGroup.questionGroup.uuid)"
            >
                <span class = "material-icons">
                    delete
                </span>
                Törlés
            </span>
        </div>
    </div>
        
    <form [formGroup] = "formGroup" class = "question-group-form">
        <div>
            <mat-form-field style = "width: 100%;">
                <mat-label> Kérdéscsoport </mat-label>
                <mat-select [formControl] = "questionGroupSelectFC">
                    <mat-option *ngFor = "let questionGroup of unassignedQuestionGroups" [value] = "questionGroup">
                        {{ questionGroup.name }} ({{ questionGroup.questionCount }})
                    </mat-option>
                    <mat-option *ngFor = "let selectedQuestionGroup of selectedQuestionGroups" disabled>
                        {{ selectedQuestionGroup.questionGroup.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class = "count-form">
            <mat-form-field style = "width: 100%;">
                <mat-label> Darab </mat-label>
                <input #questionCountInput matInput type = number min = 1 [formControl] = "questionCountFC" />
                <mat-error *ngIf = "questionCountFC.hasError('required')">
                    Kötelező megadni!
                </mat-error>
                <mat-error
                    *ngIf = "!questionCountFC.hasError('required') && (questionCountFC.hasError('wholeNumber') || questionCountFC.hasError('min') || questionCountFC.hasError('max'))"
                >
                    Érvénytelen érték!
                </mat-error>
            </mat-form-field>
        </div>
        <div style = "text-align: center;">
            <button
                class = "gradient-button add-question-group-button"
                (click) = "addToSelectedQuestionGroups()" 
                [disabled] = "isQuestionGroupsLoading || questionGroupSelectFC.value === null || questionCountFC.invalid"
                mat-ripple
            >
                Hozzáad
            </button>
        </div>
    </form>
</div>

<div class = "create-quiz-footer">
    <button
        class = "gradient-button action-button"
        [disabled] = "selectedQuestionGroups.length === 0 || isActionButtonLoading"
        [class.button-loading] = "isActionButtonLoading"
        (click) = "createQuiz()"
        mat-ripple
    >
        Kvíz létrehozása
    </button>
</div>
