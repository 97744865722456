import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Classroom, QuizSession } from 'src/app/models/classroom';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';
import { PreviousClassroomService } from 'src/app/services/previous-classroom.service';

@Component({
  selector: 'app-review-quiz-session',
  templateUrl: './review-quiz-session.component.html',
  styleUrls: ['./review-quiz-session.component.scss']
})
export class ReviewQuizSessionComponent implements OnInit {

  quizSession:QuizSession;

  constructor(
    private activatedRoute:ActivatedRoute,
    private previousClassroomService:PreviousClassroomService,
    private ongoingClassroomService:OngoingClassroomService
  ) {
    let classroom:Classroom|null;
    if(this.activatedRoute.snapshot.params.classroomUuid) {
      classroom = this.previousClassroomService.getPreviousClassroomsArrayRef().find(
        classroom => classroom.uuid === this.activatedRoute.snapshot.params.classroomUuid
      ) ?? null;
    } else {
      classroom = this.ongoingClassroomService.getOngoingClassroomRef();
    }

    const quizNumber:number|null = this.activatedRoute.snapshot.params.quizNumber ?? null;

    if(classroom === null || quizNumber === null || quizNumber === NaN || quizNumber === 0 || classroom.quizSessionHistory.length < quizNumber) {
      // TODO: handle
      return;
    }

    this.quizSession = classroom.quizSessionHistory[classroom.quizSessionHistory.length - quizNumber];
  }

  public ngOnInit():void {
    this.quizSession.actualQuestionIndex = window.history.state.questionIndex ?? 0;
  }

  public moveToPreviousQuestion():void {
    if(this.quizSession.actualQuestionIndex > 0) {
      this.quizSession.actualQuestionIndex--;
    }
  }

  public moveToNextQuestion():void {
    if(this.quizSession.actualQuestionIndex < this.quizSession.questions.length - 1) {
      this.quizSession.actualQuestionIndex++;
    }
  }

  public navigateBack():void {
    history.back();
  }

}
