import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { Settings } from 'src/app/models/settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  showStudentAnswersFC:FormControl;
  showStudentAnswersFCValueChanges:Subscription;

  constructor(
    private settingsService:SettingsService
  ) {
    this.showStudentAnswersFC = new FormControl(this.settingsService.getSettingsValue(Settings.showUserAnswers, false));
  }

  public ngOnInit():void {
    this.showStudentAnswersFCValueChanges = this.showStudentAnswersFC.valueChanges.subscribe(
      (newValue:boolean) =>  {
        this.settingsService.setSettingsValue(Settings.showUserAnswers, newValue);
        this.saveSettings();
      }
    )
  }

  public ngOnDestroy():void {
    this.showStudentAnswersFCValueChanges?.unsubscribe();
  }

  public saveSettings():void {
    this.settingsService.saveSettingsToLocalStorage();
  }

  public navigateBack():void {
    history.go(-1);
  }
}
