import { Component, Input, OnInit } from '@angular/core';
import { ClassMember, OngoingClassroom, QuizSessionQuestionAnwser } from 'src/app/models/classroom';
import { Settings } from 'src/app/models/settings';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss']
})
export class StudentListComponent implements OnInit {
  @Input() ongoingClassroom:OngoingClassroom;
  @Input() students:ReadonlyArray<ClassMember>;
  @Input() title?:string;
  @Input() messageOnEmptyList?:string;
  @Input() listOpacity:number = 1;
  @Input() showListSize:boolean = true;

  showUserAnswer:boolean;

  constructor(
    private settingsService:SettingsService
  ) { }

  public ngOnInit():void {
    this.showUserAnswer = this.settingsService.getSettingsValue(Settings.showUserAnswers);
  }

  getStudentAnswer(studentUuid:string):{ answerLetter:string, correct:boolean }|null {
    const answer:QuizSessionQuestionAnwser|undefined = this.ongoingClassroom.getCurrentQuestion()?.answers.find(answer => answer.userUuid === studentUuid);
    if(answer === undefined) {
      return null;
    }

    return {
      answerLetter: String.fromCharCode("A".charCodeAt(0) + answer.answerIndex),
      correct: answer.correct
    };
  }
}
