<div *ngIf = "isContentLoading" class = "generic-loading-container">
    <div>
        <mat-spinner class = "loading-spinner"></mat-spinner>
        <div class = "loading-text">
            {{ infoText }}
        </div>
    </div>
    
</div>
<ng-container *ngIf = "!isContentLoading">
    <router-outlet></router-outlet>
    <app-active-students
        *ngIf = "ongoingClassroom && shouldShowStudents"
        class = "active-students"
        [ongoingClassroom] = "ongoingClassroom"
    ></app-active-students>
</ng-container>
