<a [routerLink] = "'quiz/' + number + '/summary'">
    <app-fancy-card
        [headerClass] = "'mrkresz-gray-background'"
    >
        <div class = "card-header" card-header>
            <div class = "title">
                {{ number }}. kvíz
            </div>
            <div class = "subtitle">
                {{ quizSession.timestamp | date: "YYYY-MM-dd HH:mm" }}
            </div>
        </div>
        <div class = "card-content" card-content>
            <div class = "data-row">
                <div class = "icon-container">
                    <span class = "material-icons"> help_outline </span>
                </div>
                <div>
                    {{ quizSession.questions.length }} kérdés
                </div>
            </div>
            <div class = "questiong-groups-row">
                <div class = "questiong-groups-row-name">
                    Kérdéscsoportok:
                </div>
                <div class = "questiong-groups-row-data">
                    {{ questionGroups }}
                </div>
            </div>
        </div>
    </app-fancy-card>
</a>

