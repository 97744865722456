<div *ngIf = "title" class = "title">
    <div class = "title-text-container">
        <span>
            {{ title }}
        </span>
        <span *ngIf = "showListSize">
            ({{ students.length }})
        </span>
    </div>
    <div class  = "title-line">
        <!-- Line -->
    </div>
</div>
<div *ngFor = "let student of students" class = "list-item" [ngStyle] = "{ 'opacity': listOpacity }">
    <div>
        <div
            class = "profile-image"
            [ngStyle]= "{'background-image': 'url(' + (student.profileImageUrl ? student.profileImageUrl : 'assets/defaultprofile.png') + ')'}"
        >
            <!-- Profile image goes here -->
        </div>
    </div>
    <div class = "name-and-answer">
        <span class = "student-name">
            {{ student.nickname }}
        </span>
        <span
            *ngIf = "ongoingClassroom && !showUserAnswer && getStudentAnswer(student.userUuid)"
            class = "material-icons"
        >
            check_circle
        </span>
        <span
            *ngIf = "ongoingClassroom && showUserAnswer && getStudentAnswer(student.userUuid)"
            class = "answer-letter"
            [ngStyle] = "{ 'background-color': getStudentAnswer(student.userUuid)!.correct ? 'green' : 'red' }"
        >
            {{ getStudentAnswer(student.userUuid)!.answerLetter }}
        </span>
    </div>
</div>
<div *ngIf = "messageOnEmptyList && students.length === 0" class = "empty-list">
    {{ messageOnEmptyList }}
</div>