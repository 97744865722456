import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SessionService } from 'src/app/services/common/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() parentElementRef:ElementRef<HTMLElement>;

  username:string;
  userProfileImageUrl:string;

  readonly mobileModeWidthThreshold:number = 1000;
  mobileMode:boolean = true;
  resizeObserver:ResizeObserver;
  
  isMenuOpen:boolean = false;

  clickEventHandler:() => void = () => {};

  constructor(
    private elementRef:ElementRef<HTMLElement>,
    private changeDetectorRef:ChangeDetectorRef,
    private sessionService:SessionService,
    private routerService:Router
  ) {
    this.resizeObserver = new ResizeObserver(
      (entries:ResizeObserverEntry[]) => {
        this.mobileMode = entries[0].target.clientWidth < this.mobileModeWidthThreshold;
        if(this.isMenuOpen && !this.mobileMode) {
          this.toogleMenu();
        }
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  ngOnInit(): void {
    this.username = this.sessionService.getUsername() ?? "Ismeretlen felhasználó";
    this.userProfileImageUrl = this.sessionService.getProfileImageUrl() ?? "";

    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.routerService.events.subscribe((event) => {
      if(event instanceof NavigationStart && this.isMenuOpen) {
        this.toogleMenu();
      }
    });

    window.addEventListener("click", this.clickEventHandler);
  }

  ngOnDestroy():void {
    this.resizeObserver.disconnect();
    window.removeEventListener("click", this.clickEventHandler);
  }

  public toogleMenu():void {
    this.isMenuOpen = !this.isMenuOpen;
    if(this.isMenuOpen) {
      this.parentElementRef.nativeElement.style.overflow = "hidden";
      this.parentElementRef.nativeElement.style.height = "100vh";
      this.elementRef.nativeElement.classList.add("is-expanded");
    } else {
      this.elementRef.nativeElement.classList.remove("is-expanded");
      this.parentElementRef.nativeElement.style.removeProperty("overflow");
      this.parentElementRef.nativeElement.style.removeProperty("height");
    }
  }

  public menuItemClicked():void {
    if(this.isMenuOpen) {
      this.toogleMenu();
    }
  }

}
