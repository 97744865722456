import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Classroom, QuizSession } from 'src/app/models/classroom';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';
import { PreviousClassroomService } from 'src/app/services/previous-classroom.service';

@Component({
  selector: 'app-quiz-session-summary',
  templateUrl: './quiz-session-summary.component.html',
  styleUrls: ['./quiz-session-summary.component.scss']
})
export class QuizSessionSummaryComponent {

  quizSession:QuizSession;

  constructor(
    private activatedRoute:ActivatedRoute,
    private previousClassroomService:PreviousClassroomService,
    private ongoingClassroomService:OngoingClassroomService
  ) {
    const classroomUuid:string|undefined = this.activatedRoute.snapshot.params.classroomUuid;
    let classroom:Classroom|null;
    
    if(classroomUuid) {
      classroom = this.previousClassroomService.getPreviousClassroomsArrayRef().find(
        classroom => classroom.uuid === classroomUuid
      ) ?? null;
    } else {
      classroom = this.ongoingClassroomService.getOngoingClassroomRef();
    }

    const quizNumber:number|null = this.activatedRoute.snapshot.params.quizNumber ?? null;

    if(classroom === null || quizNumber === null || quizNumber === NaN || quizNumber === 0 || classroom.quizSessionHistory.length < quizNumber) {
      // TODO: handle
      return;
    }

    this.quizSession = classroom.quizSessionHistory[classroom.quizSessionHistory.length - quizNumber];
  }

  public navigateBack():void {
    history.back();
  }

}
