<div>
    <div class = "upper">
        A keresett oldal nem található.
    </div>
    <a [routerLink] = "'/dashboard'">
        <div class = "lower">
            Vissza a főoldalra
        </div>
    </a>
</div>

