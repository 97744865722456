<a [routerLink] = "'..'">
    <button class = "back-button-container action-button" matRipple>
        <span class = "material-icons">
            arrow_back
        </span>
        Vissza
    </button>
</a>

<div class = "title">
    Korábbi óra megtekintése
</div>
<div *ngIf = "classroom" class = "date">
    {{ classroom.created.timestamp | date: "YYYY-MM-dd HH:mm" }}
</div>

<!-- Previous quizzes -->
<div class = "page-subtitle">
    Kvízek az órán
</div>
<div *ngIf = "classroom" class = "previous-quizzes">
    <app-quiz-session-card
        *ngFor = "let quizSession of classroom.quizSessionHistory; let i = index"
        [quizSession] = "quizSession"
        [number] = "classroom.quizSessionHistory.length - i"
    ></app-quiz-session-card>
    <div *ngIf = "classroom.quizSessionHistory.length === 0" class = "no-quizzes">
        Az órán nem indítottál egyetlen kvízt sem.
    </div>
</div>
