import { Injectable } from '@angular/core';
import { copyArrayToEnd } from '../functions/array-functions';
import { HttpResponseData } from '../models/common/http';
import { QuestionGroup, questionGroupSorter } from '../models/question-group';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionGroupService {
  private static readonly apiUrlFragment:string = "/api/instructor/classroom/question_groups"
  private questionGroups:Array<QuestionGroup>;

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.questionGroups = new Array<QuestionGroup>();
  }

  public async getQuestionGroups():Promise<ReadonlyArray<QuestionGroup>> {
    try {
      const response:HttpResponseData<Array<QuestionGroup>> = await this.backendService.callApi(QuestionGroupService.apiUrlFragment, "GET");
      this.questionGroups.length = 0;
      copyArrayToEnd(this.questionGroups, response.data)
      this.questionGroups.sort(questionGroupSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdéscsoportok lekérdezése közben.");
    }

    return this.questionGroups;
  }

  public clearCachedData():void {
    this.questionGroups.length = 0;
  }


}
