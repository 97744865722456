<!-- Back button -->
<a [routerLink] = "'..'">
    <button class = "back-button-container action-button" matRipple>
        <span class = "material-icons">
            arrow_back
        </span>
        <span>
            Vissza
        </span>
    </button>
</a>

<!-- Close classroom button -->
<button
    class = "close-classroom-button-container action-button"
    matRipple
    [disabled] = "isCloseClassroomButtonLoading"
    [class.button-loading] = "isCloseClassroomButtonLoading"
    (click) = "closeClassroom()"
>
    <span class = "material-icons">
        clear
    </span>
    <span>
        Óra lezárása
    </span>
</button>

<!-- Actual classroom card -->
<div *ngIf = "ongoingClassroom" class = "ongoing-classroom-card-container">
    <app-fancy-card
        class = "ongoing-classroom-card"
        [headerClass] = "'mrkresz-gradient-background'"
    >
        <div class = "ongoing-classroom-card-header" card-header>
            Aktuális óra
        </div>
        <div class = "ongoing-classroom-card-content" card-content>
            <div class = "data-row">
                <div class = "icon-container">
                    <span class = "material-icons"> help_outline </span>
                </div>
                <div>
                    {{ ongoingClassroom.quizSessionHistory.length }} kvíz
                </div>
            </div>
            <div class = "data-row">
                <div class = "icon-container">
                    <span class = "material-icons"> account_circle </span>
                </div>
                <div>
                    {{ ongoingClassroom.activeStudents.length }} tanuló vesz részt az órán
                </div>
            </div>
            <div class = "access-code-button-container">
                <a [routerLink] = "'./access-code'">
                    <button class = "access-code-button gradient-button" mat-ripple>
                        <div class = "access-code-button-icon">
                            <span class = "material-icons"> open_in_full </span>
                        </div>
                        <div class = "access-code-button-texts">
                            <div class = "access-code-title">
                                Belépési kód
                            </div>
                            <div class = "access-code">
                                {{ ongoingClassroom.accessId }}
                            </div>
                        </div>
                    </button>
                </a>
            </div>
        </div>
    </app-fancy-card>
</div>

<!-- Quiz button -->
<div *ngIf = "ongoingClassroom" class = "quiz-button-container">
    <a [routerLink] = "ongoingClassroom.currentQuizSession ? 'quiz' : 'create-quiz'">
        <button class = "quiz-button gradient-button" mat-ripple>
            <span *ngIf = "ongoingClassroom.currentQuizSession">
                Belépés a folyamatban lévő kvízbe
            </span>
            <span *ngIf = "!ongoingClassroom.currentQuizSession">
                Új kvíz létrehozása
            </span>
        </button>
    </a>
</div>

<!-- Previous quizzes -->
<div class = "page-subtitle">
    Kvízek az órán
</div>
<div *ngIf = "ongoingClassroom" class = "previous-quizzes">
    <app-quiz-session-card
        *ngFor = "let quizSession of ongoingClassroom.quizSessionHistory; let i = index"
        [quizSession] = "quizSession"
        [number] = "ongoingClassroom.quizSessionHistory.length - i"
    ></app-quiz-session-card>
    <div *ngIf = "ongoingClassroom.quizSessionHistory.length === 0" class = "no-quizzes">
        Az órán nem indítottál egyetlen kvízt sem.
    </div>
</div>
