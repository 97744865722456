import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Classroom } from 'src/app/models/classroom';
import { OngoingClassroom } from 'src/app/models/classroom';
import { PreviousClassroomService } from 'src/app/services/previous-classroom.service';
import { OngoingClassroomService } from 'src/app/services/ongoing-classroom.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-classroom-list',
  templateUrl: './classroom-list.component.html',
  styleUrls: ['./classroom-list.component.scss']
})
export class ClassroomListComponent implements OnInit, OnDestroy {

  ongoingClassroom:OngoingClassroom|null = null;
  previousClassrooms:ReadonlyArray<Classroom> = [];
  ongoingClassroomClosingSubsription:Subscription;

  isCreateNewClassroomButtonLoading:boolean = false;
  isPreviousClassroomsLoading:boolean = false;

  constructor(
    private previousClassroomService:PreviousClassroomService,
    private ongoingClassroomService:OngoingClassroomService,
    private routerService:Router,
    private activatedRoute:ActivatedRoute,
    private snackBarService:MatSnackBar
  ) { }

  public async ngOnInit():Promise<void> {
    this.ongoingClassroom = this.ongoingClassroomService.getOngoingClassroomRef();

    this.ongoingClassroomClosingSubsription = this.ongoingClassroomService.getOngoingClassroomChangeSubscription(
      (newState:OngoingClassroom|null) => {
        this.ongoingClassroom = newState;
      }
    );

    this.isPreviousClassroomsLoading = true;
    this.previousClassrooms = await this.previousClassroomService.getPreviousClassrooms();
    this.isPreviousClassroomsLoading = false;
  }

  public ngOnDestroy():void {
    this.ongoingClassroomClosingSubsription?.unsubscribe();
  }

  public async handleCreateClassroomButtonClick():Promise<void> {
    this.isCreateNewClassroomButtonLoading = true;

    try {
      const newClassroom:OngoingClassroom = await this.ongoingClassroomService.createClassroom();
      this.routerService.navigate(["../ongoing"], { relativeTo: this.activatedRoute, state: { ongoingClassroom: newClassroom } });
    } catch(error:any) {
      let snackBarMessage:string = "Hiba az óra indításakor.";
      switch(error.error) {
        case "ALREADY_OPEN_ROOM":
          snackBarMessage = "Már van folyamatban lévő órád. Lépj be!";
          break;
      }
      this.snackBarService.open(snackBarMessage, "Bezár", { duration: 3000 })
      console.error(error);
    }

    this.isCreateNewClassroomButtonLoading = false;
  }

}
