// ########## Modules and services ##########
// Built-in components and modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
// Angular material modules
import { MatBadgeModule } from '@angular/material/badge'; 
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// ########## Componetns ##########
// Built-in components
import { AppComponent } from './app.component';
// Custom components
import { ClassroomsComponent } from './components/dashboard/classrooms/classrooms.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/misc/page-not-found/page-not-found.component';
import { ClassroomListComponent } from './components/dashboard/classrooms/classroom-list/classroom-list.component';
import { AccessNumberPipe } from './pipes/access-number.pipe';
import { QuizSessionCardComponent } from './components/dashboard/classrooms/classroom-details/quiz-session-card/quiz-session-card.component';
import { QuizSessionQuestionComponent } from './components/dashboard/classrooms/classroom-details/quiz-session-question/quiz-session-question.component';
import { QuizSessionQuestionOptionComponent } from './components/dashboard/classrooms/classroom-details/quiz-session-question/quiz-session-question-option/quiz-session-question-option.component';
import { ActiveStudentsComponent } from './components/dashboard/classrooms/active-students/active-students.component';
import { CreateQuizComponent } from './components/dashboard/classrooms/classroom-details/create-quiz/create-quiz.component';
import { OngoingQuizSessionComponent } from './components/dashboard/classrooms/classroom-details/ongoing-quiz-session/ongoing-quiz-session.component';
import { AccessCodeViewComponent } from './components/dashboard/classrooms/classroom-details/access-code-view/access-code-view.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './components/dashboard/header/header.component';
import { OngoingClassroomComponent } from './components/dashboard/classrooms/ongoing-classroom/ongoing-classroom.component';
import { ReviewQuizSessionComponent } from './components/dashboard/classrooms/classroom-details/review-quiz-session/review-quiz-session.component';
import { QuizSessionSummaryComponent } from './components/dashboard/classrooms/classroom-details/quiz-session-summary/quiz-session-summary.component';
import { QuizSessionSummaryQuestionCardComponent } from './components/dashboard/classrooms/classroom-details/quiz-session-summary/quiz-session-summary-question-card/quiz-session-summary-question-card.component';
import { PreviousClassroomComponent } from './components/dashboard/classrooms/previous-classroom/previous-classroom.component';
import { IconButtonComponent } from './components/misc/icon-button/icon-button.component';
import { StudentListComponent } from './components/dashboard/classrooms/active-students/student-list/student-list.component';
import { SettingsComponent } from './components/dashboard/settings/settings.component';
import { FitTextDirective } from './directives/fit-text.directive';
import { HeaderMenuComponent } from './components/dashboard/header/header-menu/header-menu.component';
import { StudentListsContainerComponent } from './components/dashboard/classrooms/active-students/student-lists-container/student-lists-container.component';
import { ActiveStudentsDialogComponent } from './components/dashboard/classrooms/active-students-dialog/active-students-dialog.component';
import { OngoingClassroomCardComponent } from './components/dashboard/classrooms/classroom-list/ongoing-classroom-card/ongoing-classroom-card.component';
import { PreviousClassroomCardComponent } from './components/dashboard/classrooms/classroom-list/previous-classroom-card/previous-classroom-card.component';
import { NewClassroomButtonComponent } from './components/dashboard/classrooms/classroom-list/new-classroom-button/new-classroom-button.component';
import { FancyCardComponent } from './components/misc/fancy-card/fancy-card.component';
import { AlreadyRunningQuizDialogComponent } from './components/dashboard/classrooms/classroom-details/create-quiz/already-running-quiz-dialog/already-running-quiz-dialog.component';
import { TopUsersDialogComponent } from './components/dashboard/classrooms/classroom-details/ongoing-quiz-session/top-users-dialog/top-users-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ClassroomsComponent,
    LoginComponent,
    PageNotFoundComponent,
    ClassroomListComponent,
    AccessNumberPipe,
    QuizSessionCardComponent,
    QuizSessionQuestionComponent,
    QuizSessionQuestionOptionComponent,
    ActiveStudentsComponent,
    CreateQuizComponent,
    OngoingQuizSessionComponent,
    AccessCodeViewComponent,
    DashboardComponent,
    HeaderComponent,
    OngoingClassroomComponent,
    ReviewQuizSessionComponent,
    QuizSessionSummaryComponent,
    QuizSessionSummaryQuestionCardComponent,
    PreviousClassroomComponent,
    IconButtonComponent,
    StudentListComponent,
    SettingsComponent,
    FitTextDirective,
    HeaderMenuComponent,
    StudentListsContainerComponent,
    ActiveStudentsDialogComponent,
    OngoingClassroomCardComponent,
    PreviousClassroomCardComponent,
    NewClassroomButtonComponent,
    FancyCardComponent,
    AlreadyRunningQuizDialogComponent,
    TopUsersDialogComponent
  ],
  imports: [
    // Built-in components and modules
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // Angular material modules
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
