import { Component, Input, OnInit } from '@angular/core';
import { reduceToSum } from 'src/app/functions/array-functions';
import { QuizSessionQuestion } from 'src/app/models/classroom';

@Component({
  selector: 'app-quiz-session-summary-question-card',
  templateUrl: './quiz-session-summary-question-card.component.html',
  styleUrls: ['./quiz-session-summary-question-card.component.scss']
})
export class QuizSessionSummaryQuestionCardComponent implements OnInit {
  String = String;

  @Input() quizSessionQuestion:QuizSessionQuestion;
  @Input() questionIndex:number;

  numberOfCorrectAnswers:number;
  numberOfIncorrectAnswers:number;

  constructor() { }

  ngOnInit(): void {
    this.numberOfCorrectAnswers = this.quizSessionQuestion.answers.map(
      answer => {
        return +(answer.answerIndex === this.quizSessionQuestion.question.options.findIndex(
          option => option.isSolution
        ));
      }
    ).reduce(reduceToSum, 0);

    this.numberOfIncorrectAnswers = this.quizSessionQuestion.answers.length - this.numberOfCorrectAnswers;
  }

}
