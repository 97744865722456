<div class = "title">
    Hiba a kvíz létrehozása közben
</div>
<div class = "text">
    Az órádban jelenleg már fut egy aktív kvíz. Ha szeretnél újat indítani, akkor először lépj be és zárd be az aktuális kvízt.
</div>
<div class = "dialog-footer">
    <button
        class = "back-button"
        mat-dialog-close
        mat-ripple
    >
        <span>
            Vissza
        </span>
    </button>
    <button
        class = "go-button mrkresz-gradient-background"
        mat-dialog-close = "true"
        mat-ripple
    >
        Belépés a kvízbe
    </button>
</div>