import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OngoingClassroom } from 'src/app/models/classroom';

@Component({
  selector: 'app-ongoing-classroom-card',
  templateUrl: './ongoing-classroom-card.component.html',
  styleUrls: ['./ongoing-classroom-card.component.scss']
})
export class OngoingClassroomCardComponent implements OnInit, OnDestroy {
  @Input() ongoingClassroom:OngoingClassroom;

  startedAgoString:string;

  intervalId:any = null;

  constructor() { }

  ngOnInit(): void {
    this.setStartedAgo();
    this.intervalId = setInterval(this.setStartedAgo, 5000);
  }

  ngOnDestroy(): void {
    if(this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  }

  private setStartedAgo = () => {
    const startedAgoInMs:number = Date.now().valueOf() - this.ongoingClassroom.created.timestamp;
    const oneMinuteInMs:number = 1000 * 60;
    const oneHourInMs:number = oneMinuteInMs * 60;
    const oneDayInMs:number = oneHourInMs * 24;

    if(startedAgoInMs < oneHourInMs) {
      const inMinutes:number = Math.floor(startedAgoInMs / oneMinuteInMs);
      this.startedAgoString = `Indítva ${inMinutes} perccel ezelőtt`;
    } else if(startedAgoInMs < oneDayInMs) {
      const inHours:number = Math.floor(startedAgoInMs / oneHourInMs);
      this.startedAgoString = `Indítva ${inHours} órával ezelőtt`;
    } else {
      const inDays:number = Math.floor(startedAgoInMs / oneDayInMs);
      this.startedAgoString = `Indítva ${inDays} nappal ezelőtt`;
    }
  }

}
