import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { awaitFor } from '../functions/misc';

@Directive({
  selector: '[fit-text]'
})
export class FitTextDirective implements OnInit, AfterViewInit, OnDestroy {
  public static readonly defaultMinFontSizeInPixels:number = 1;
  public static readonly defaultMaxFontSizeInPixels:number = 200;

  @Input('minFontSize') minFontSize:number;
  @Input('maxFontSize') maxFontSize:number;
  @Input('step') step:number = 1;
  @Input('unit') unit:"px"|"em" = "px";
  @Input('wrapperClasses') wrapperClasses:string = "";

  resizeObserver:ResizeObserver;
  mutationObserver:MutationObserver;

  wrapper:HTMLDivElement;

  mostRecentCalculationTimestamp:number;

  constructor(
    private element:ElementRef<HTMLElement>,
    private renderer:Renderer2
  ) {
    this.resizeObserver = new ResizeObserver(
      (entries:Array<ResizeObserverEntry>) => {
        this.calculateAndSetFontSize();
      }
    );

    this.mutationObserver = new MutationObserver(
      (records:Array<MutationRecord>) => {
        this.calculateAndSetFontSize();
      }
    );
  }

  public ngOnInit():void {
    this.wrapper = this.renderer.createElement("div");
    if(this.wrapperClasses) {
      this.wrapper.classList.add(...this.wrapperClasses.split(" "));
    }

    this.element.nativeElement.childNodes.forEach(
      childNode => {
        this.wrapper.appendChild(childNode);
      }
    );
    this.element.nativeElement.appendChild(this.wrapper);

    this.element.nativeElement.style.overflow = "hidden";

    switch(this.unit) {
      case "px":
        this.minFontSize ??= FitTextDirective.defaultMinFontSizeInPixels;
        this.maxFontSize ??= FitTextDirective.defaultMaxFontSizeInPixels;
        this.step ??= 1;
        break;
      case "em":
        this.minFontSize ??= 0.1;
        this.maxFontSize ??= 10;
        this.step ??= 0.1;
        break;
    }

    this.resizeObserver.observe(this.element.nativeElement);
    this.mutationObserver.observe(this.element.nativeElement, { characterData: true, childList: true, subtree: true });
  }

  public ngAfterViewInit():void {
    this.calculateAndSetFontSize();
  }

  public ngOnDestroy():void {
    this.resizeObserver?.disconnect();
    this.mutationObserver?.disconnect();
  }

  public async calculateAndSetFontSize():Promise<void> {
    let actualCalculationTimestamp:number = Date.now();
    this.mostRecentCalculationTimestamp = actualCalculationTimestamp;

    let fontSize:number = this.minFontSize;
    let isTextOverflown:boolean = false;
    while(!isTextOverflown && fontSize <= this.maxFontSize) {
      if(actualCalculationTimestamp !== this.mostRecentCalculationTimestamp) {
        return;
      }

      this.element.nativeElement.style.fontSize = `${fontSize}${this.unit}`;
      isTextOverflown = this.element.nativeElement.clientHeight < this.wrapper.clientHeight;
      fontSize += this.step;
    }

    this.element.nativeElement.style.fontSize = `${Math.max(fontSize - 2, this.minFontSize)}${this.unit}`;
  }

}
