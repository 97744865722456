import { reduceToSum } from "../functions/array-functions";
import { Question } from "./question";

type ClassroomCreationInfo = {
    instructorUuid:string;
    instructorName:string;
    timestamp:number;
};

export class Classroom {
    constructor(
        public uuid:string,
        public accessId:string,
        public created:ClassroomCreationInfo,
        public visitors:Array<ClassMember> = [],
        public quizSessionHistory:Array<QuizSession> = []
    ) {}
}

export class OngoingClassroom extends Classroom {
    constructor(
        uuid:string,
        accessId:string,
        created:ClassroomCreationInfo,
        visitors:Array<ClassMember> = [],
        quizSessionHistory:Array<QuizSession> = [],
        public activeStudents:Array<ClassMember> = [],
        public activeStudentsInQuiz:Array<ClassMember> = [],
        public currentQuizSession:QuizSession|null = null
    ) {
        super(uuid, accessId, created, visitors, quizSessionHistory);
    }

    public getCurrentQuestion():QuizSessionQuestion|null {
        if(this.currentQuizSession === null) {
            return null;
        }

        return this.currentQuizSession.questions[this.currentQuizSession.actualQuestionIndex];
    }
}

export class ClassMember {
    userUuid:string;
    nickname:string;
    profileImageUrl:string;
}

export class QuizSession {
    uuid:string;
    timestamp:number;
    settings:QuizSessionSettings;
    questions:Array<QuizSessionQuestion>;
    actualQuestionIndex:number;
    isActualQuestionEvaulated?:boolean;
}

export class QuizSessionSettings {
    generationRule:Array<QuizGenerationRuleItem>;
    isContest:boolean;
    timeLimitInSec:number|null;
}

export class QuizGenerationRuleItem {
    questionGroupUuid:string;
    questionGroupName?:string;
    count:number;
}

export class QuizSessionQuestion {
    question:Question;
    answers:Array<QuizSessionQuestionAnwser>;
}

export class QuizSessionQuestionAnwser {
    userUuid:string;
    answerIndex:number;
    correct:boolean;
    answerTimeInMs:number;
}

export function classroomSorter(classroom1:Classroom, classroom2:Classroom):number {
    return classroom2.created.timestamp - classroom1.created.timestamp;
}

export function quizSessionSorter(quizSession1:QuizSession, quizSession2:QuizSession):number {
    return quizSession2.timestamp - quizSession1.timestamp;
}

export function getOptionPercentageFromAnswers(userAnswers:Array<QuizSessionQuestionAnwser>, optionIndex:number):number {
    return userAnswers.length !== 0 ? Math.floor(
        userAnswers.map(
          (quizSessionQuestionAnswser:QuizSessionQuestionAnwser) => {
            return +(quizSessionQuestionAnswser.answerIndex === optionIndex)
          } 
        ).reduce(reduceToSum, 0) / userAnswers.length * 100
      ) : 0;
}

export function getNumberOfAnswersInCurrentQuiz(ongoingClassroom:OngoingClassroom):number {
    if(ongoingClassroom.currentQuizSession === null) {
        return 0;
      }
  
    return ongoingClassroom.activeStudentsInQuiz.filter(
        (student:ClassMember) =>  {
            return ongoingClassroom.currentQuizSession!.questions[ongoingClassroom.currentQuizSession!.actualQuestionIndex].answers.some(
            answer => answer.userUuid === student.userUuid
            )
        } 
    ).length;
}