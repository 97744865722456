import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { SessionService } from './session.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

const sessionNotRequiredApis:ReadonlyArray<string> = [
  "admin/login"
];

function isSessionRequired(apiUrlFragment:string):boolean {
  return !sessionNotRequiredApis.includes(apiUrlFragment);
}


@Injectable({
  providedIn: 'root'
})
export class BackendService {
  public static readonly authorizationUuidHeaderName:string = "authorization-uuid";
  public static readonly authorizationTokenHeaderName:string = "authorization-token";

  constructor(
    private httpClient:HttpClient,
    private sessionService:SessionService
  ) {}

  public async callApi<RequestBodyType = Object, ResponseBodyType = Object>(
    apiUrlFragment:string,
    httpRequestMethod:HttpRequestMethod,
    httpRequestBody?:RequestBodyType,
    files?:ReadonlyMap<string, File>,
    callbacks?:HttpCallbacks<ResponseBodyType>
  ):Promise<ResponseBodyType> {
    
    // Construct the HTTP request object
    const httpRequest:HttpRequest<RequestBodyType|FormData> = this.createHttpRequest(apiUrlFragment, httpRequestMethod, httpRequestBody, files, callbacks);

    // Create the HTTP event observable with the HTTP request
    const httpEventObservable:Observable<HttpEvent<ResponseBodyType>> = this.httpClient.request<ResponseBodyType>(httpRequest);

    try {
      return await this.createHttpResponsePromise(httpEventObservable, callbacks);
    } catch(rejectionReason:any) {
      throw rejectionReason;
    }
  }

  private createHttpRequest<RequestBodyType, ResponseBodyType>(
    apiUrlFragment:string,
    httpRequestMethod:HttpRequestMethod,
    httpRequestBody?:RequestBodyType,
    files?:ReadonlyMap<string, File>,
    callbacks?:HttpCallbacks<ResponseBodyType>
  ):HttpRequest<RequestBodyType|FormData> {
    // Construct the API URL
    const apiUrl:string = environment.serverAddress + apiUrlFragment;

    // Construct the HTTP options (headers, should report progress, etc.)
    let httpHeaders:HttpHeaders = new HttpHeaders({});
    if(isSessionRequired(apiUrlFragment)) {
      httpHeaders = httpHeaders
      .set(BackendService.authorizationUuidHeaderName, this.sessionService.getUuid() ?? "")
      .set(BackendService.authorizationTokenHeaderName, this.sessionService.getSessionId() ?? "");
    }

    const reportProgress:boolean = callbacks !== undefined && (callbacks.onDownloadProgress !== undefined || callbacks?.onUploadProgress !== undefined);
    const httpOptions:HttpOptions = {
      headers: httpHeaders,
      responseType: "json",
      reportProgress: reportProgress
    }

    // Construct and return the HTTP request
    switch(httpRequestMethod) {
      case "DELETE":
      case "GET":
        return new HttpRequest<RequestBodyType>(httpRequestMethod, apiUrl, httpOptions);
      case "POST":
      case "PUT":
        if(files !== undefined) {
          const formData:FormData = new FormData();

          if(httpRequestBody) {
            formData.append("data", JSON.stringify(httpRequestBody));
          }

          for(const [fileName, file] of files) {
            formData.append(fileName, file);
          }

          return new HttpRequest<FormData>(httpRequestMethod, apiUrl, formData, httpOptions);
        } else {
          httpRequestBody ??= {} as RequestBodyType;
          return new HttpRequest<RequestBodyType>(httpRequestMethod, apiUrl, httpRequestBody, httpOptions);
        }

    }
  }

  private createHttpResponsePromise<ResponseBodyType>(
    httpEventObservable:Observable<HttpEvent<ResponseBodyType>>,
    callbacks?:HttpCallbacks<ResponseBodyType>
  ):Promise<ResponseBodyType> {
    return new Promise<ResponseBodyType>(
      (resolve:(value:ResponseBodyType|PromiseLike<ResponseBodyType>) => void, reject:(reason:any) => void) => {
        const httpEventSubscription:Subscription = httpEventObservable.subscribe(
          (httpEvent:HttpEvent<ResponseBodyType>) => {
            switch(httpEvent.type) {

              case HttpEventType.Sent:
                callbacks?.onSent?.();
                break;

              case HttpEventType.UploadProgress:
                callbacks?.onUploadProgress?.(httpEvent.loaded, httpEvent.total);
                break;

              case HttpEventType.ResponseHeader:
                callbacks?.onResponseHeader?.(httpEvent);
                break;

              case HttpEventType.DownloadProgress:
                callbacks?.onDownloadProgress?.(httpEvent.loaded, httpEvent.total);
                break;

              case HttpEventType.Response:
                switch(httpEvent.status) {
                  case 200:
                  case 304:
                    const httpResponseBody:ResponseBodyType|null = httpEvent.body;
                    if(httpResponseBody === null) {
                      reject("NO_BODY_IN_RESPONSE");
                      return;
                    }
                    
                    resolve(httpResponseBody);
                    break;

                  default:
                    reject(httpEvent);
                }
                httpEventSubscription.unsubscribe();
                break;
                
              default:
                // HttpEventType.User falls here -- nothing to do
            }
          },
          (httpResponseError:any) => {
            reject(httpResponseError);
          }
        );
      }
    );
  }
}

export type HttpRequestMethod = "DELETE"|"GET"|"POST"|"PUT";

export class HttpCallbacks<ResponseBodyType = any> {
  onSent?:() => void;
  onUploadProgress?:(loaded:number, total?:number) => void;
  onResponseHeader?:(httpEvent:HttpEvent<ResponseBodyType>) => void;
  onDownloadProgress?:(loaded:number, total?:number) => void;
}

class HttpOptions {
  headers?:HttpHeaders;
  context?:HttpContext;
  reportProgress?:boolean;
  params?:HttpParams;
  responseType?:'arraybuffer'|'blob'|'json' | 'text';
  withCredentials?: boolean;
}