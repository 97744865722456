import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accessNumber'
})
export class AccessNumberPipe implements PipeTransform {

  transform(value:string):string {
    if(value.length < 4) {
      return value;
    }

    return value.slice(0, 3) + " " + value.slice(3);
  }

}
