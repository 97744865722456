<div class = "header">
    <div class = "title">
        Tanulók listája
    </div>
    <div class = "close" mat-dialog-close>
        ×
    </div>
</div>
<mat-dialog-content class = "mat-dialog-content">
    <app-student-lists-container
        [isThereCurrentQuizSession] = "isThereCurrentQuizSession"
        [ongoingClassroom] = "data.ongoingClassroom"
    ></app-student-lists-container>
</mat-dialog-content>

