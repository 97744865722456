import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-classroom-button',
  templateUrl: './new-classroom-button.component.html',
  styleUrls: ['./new-classroom-button.component.scss']
})
export class NewClassroomButtonComponent implements OnInit {
  @Input() isLoading = false;

  constructor() { }

  ngOnInit(): void {
  }

}
