<div class = "login-wrapper">
    <div class = "logo">
        <img src = "assets/images/app-logo.png" />
        <div class = "name">
            Mr. Kresz Classroom
        </div>
    </div>
    <form class = "form" [formGroup] = "loginFG">
        <div>
            <mat-form-field class = "input-field">
                <mat-label> E-mail cím</mat-label>
                <input matInput formControlName = "emailFC" />
                <mat-error> Kötelező mező! </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class = "input-field">
                <mat-label> Jelszó </mat-label>
                <input matInput [type] = "showPassword ? 'text' : 'password'" formControlName = "passwordFC" >
                <button mat-icon-button matSuffix type = "button" (click) = "showPassword = !showPassword" tabindex = "-1">
                    <mat-icon> {{ showPassword ? 'visibility' : 'visibility_off'}} </mat-icon>
                </button>
                <mat-error> Kötelező mező! </mat-error>
            </mat-form-field>
        </div>
        <div class = "login-button-container">
            <button mat-flat-button color = "primary" (click) = "login()" [disabled] = "!emailFC.value && !passwordFC.value">
                Bejelentkezés
            </button>
        </div>
    </form>
</div>
