<div class = "header">
    <div class = "title">
        {{ classroom.created.timestamp | date: "YYYY-MM-dd HH:mm" }}
    </div>
</div>
<div class = "content">
    <div class = "data-row">
        <div class = "icon-container">
            <span class = "material-icons"> help_outline </span>
        </div>
        <div>
            {{ classroom.quizSessionHistory.length }} kvíz
        </div>
    </div>
    <div class = "data-row">
        <div class = "icon-container">
            <span class = "material-icons"> account_circle </span>
        </div>
        <div>
            {{ classroom.visitors.length }} tanuló csatlakozott
        </div>
    </div>
</div>