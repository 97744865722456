import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
    static wholeNumber(control:AbstractControl):ValidationErrors | null  {
        if(control.value === null || control.value === NaN || control.value === Infinity || control.value - Math.floor(control.value) !== 0) {
            return { wholeNumber: true };
        } else {
            return null;
        }
    }

    static positiveNumber(control:AbstractControl):ValidationErrors | null  {
        if(control.value === null || control.value === NaN || control.value === Infinity || control.value < 0) {
            return { positiveNumber: true };
        } else {
            return null;
        }
    }

    static positiveWholeNumber(control:AbstractControl):ValidationErrors | null  {
        if(control.value === null || control.value === NaN || control.value === Infinity || control.value < 0 || control.value - Math.floor(control.value) !== 0) {
            return { positiveWholeNumber: true };
        } else {
            return null;
        }
    }

    static valueOneOf<ValueType>(acceptableValues:Array<ValueType>):ValidatorFn {
        return function (control:AbstractControl):ValidationErrors|null {
            return !acceptableValues.includes(control.value) ? { invalidValue: true } : null;
        }
    }

    static valueNoneOf<ValueType>(unacceptableValues:Array<ValueType>):ValidatorFn {
        return function (control:AbstractControl):ValidationErrors|null {
            return unacceptableValues.includes(control.value) ? { invalidValue: true } : null;
        }
    }
}